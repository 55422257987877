import InfiniteScroll from 'react-infinite-scroll-component'
import { Icon, Text } from '@dlpco/ginga-stone'
import { ExclamationIllustration, LoadingMoreIllustration } from 'design-elements/shared/illustrations'
import styled, { css } from 'styled-components'
import { ifProp, prop, theme } from 'styled-tools'

import { Box, Flex } from '~/domains/platform/design-system'
import { Dimmer } from '~/domains/platform/design-system/dimmer'
import { Loader } from '~/domains/platform/design-system/loader/loader'
import { Choose } from '~/domains/platform/lib/utilities-components'
import { toRem } from '~/lib/helpers'
import { MainContent } from '~/ui/components/layouts/main-content'
import { EmptyState } from '~/ui/components/utils/empty-state'
import { type InfiniteListHook, useInfiniteList } from '~/ui/contexts'

type InfiniteScrollListItemGroupHeaderProps = {
  bordered?: boolean
  fontWeight?: string
}

export const InfiniteScrollListItemGroupHeader = styled(Flex)<InfiniteScrollListItemGroupHeaderProps>`
  ${ifProp(
    'bordered',
    css`
      border-bottom: 1px solid ${theme('colors.lightGray')};
    `
  )};
  line-height: ${toRem(32)};
  font-size: ${toRem(16)};
  font-weight: ${ifProp(
    'fontWeight',
    theme(`fontWeights.${prop('fontWeight')}`),
    css`
      ${theme('fontWeights.bold')}
    `
  )};
  padding: ${theme('space.medium')} ${theme('space.xLarge')};
`

InfiniteScrollListItemGroupHeader.defaultProps = {
  backgroundColor: 'white',
  color: 'mediumGray',
  bordered: true
}

export const InfiniteScrollListItemGroupDate = styled.div<{ color?: string; fontWeight?: string }>`
  position: relative;
  z-index: 0;
  color: inherit;
  font-weight: ${ifProp(
    'fontWeight',
    theme(`fontWeights.${prop('fontWeight')}`),
    css`
      ${theme('fontWeights.regular')}
    `
  )};
  flex-grow: 1;
`

export const InfiniteScrollListItemGroupBalance = styled(Text)`
  font-size: ${toRem(16)};
  color: ${theme('colors.title.primary')};
`

export const InfiniteScrollListItemShadow = styled.div<{ isOpen?: boolean }>`
  box-shadow: ${ifProp('isOpen', '0px 0px 16px rgba(0, 0, 0, 0.15)', 'unset')};
  border-radius: ${theme('radii.default')};
  cursor: pointer;
  position: relative;
  z-index: 1;

  &:not(:last-child) {
    border-bottom: 1px solid ${theme('colors.lightGray')};
  }
`

export const InfiniteScrollListItemWrapper = styled(Flex)`
  cursor: pointer;
  padding: ${theme('space.medium')} ${theme('space.xLarge')};
  align-items: center;
`

export const InfiniteScrollListItemIcon = styled(Flex)`
  align-items: center;
  display: block;
`

export const InfiniteScrollListItemInfo = styled.div`
  align-self: center;
  padding-left: ${toRem(22)};
  flex-grow: 3;
  width: auto;
  max-width: 80%;
`

export const InfiniteScrollListItemHeader = styled.div`
  font-weight: medium;
  color: ${theme('colors.darkGray')};
  font-size: ${toRem(18)};
`

export const InfiniteScrollListItemDescription = styled.div`
  color: ${theme('colors.mediumGray')};
  font-size: ${toRem(16)};
`

export const InfiniteScrollListItemAmount = styled(Text)`
  font-size: ${toRem(16)};
  line-height: ${toRem(20)};
  font-weight: medium;
  flex-grow: 1;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  color: ${theme('colors.title.primary')};
`

export const InfiniteScrollListItemTime = styled(Text)`
  font-size: ${theme('fontSizes.normal')};
  line-height: ${toRem(25)};
  font-weight: ${theme('fontWeights.body')};
  color: ${theme('colors.mediumGray2')};
`

interface InfiniteScrollListProps {
  infiniteListHook?: InfiniteListHook
  header?: React.ReactNode
  render: (props: any) => JSX.Element
  rightButton?: JSX.Element
  title?: string
  transformData?: (data: any[]) => any[]
  externalLoading?: boolean
  externalData?: any[]
}

function LoadingMore() {
  return (
    <Flex justifyContent="center" p={3}>
      <LoadingMoreIllustration />
    </Flex>
  )
}

export function InfiniteScrollList(props: InfiniteScrollListProps) {
  const {
    transformData = (x: any) => x,
    externalLoading,
    externalData = [],
    render,
    header,
    infiniteListHook = useInfiniteList
  } = props

  const { status, list = [], pagination, filter = {}, filterActions, revalidate } = infiniteListHook()

  const isError = status === 'rejected'
  const isResolved = status === 'resolved'
  const isUpdating = status === 'refreshing'

  const isFilter = Boolean(Object.values(filter).filter(value => Boolean(value)).length > 0)
  const dataList = [...list, ...externalData]

  return (
    <MainContent>
      {header}
      {(isResolved || isUpdating) && !externalLoading ? (
        <Choose>
          <Choose.When condition={dataList.length === 0 && isFilter}>
            <Box py="xxLarge">
              <EmptyState
                callToAction="Limpar filtros"
                action={() => filterActions?.reset()}
                illustration={
                  <Icon use="extract-outline" color="neutralLow" style={{ width: '85px', height: '85px' }} />
                }
                title="Oops, ainda não tem links em aberto"
                body="Crie seus links de pagamento e veja os que estão em aberto por aqui com facilidade."
              />
            </Box>
          </Choose.When>
          <Choose.Otherwise
            render={() => (
              <InfiniteScroll
                scrollThreshold={0.35}
                dataLength={dataList.length}
                next={pagination.forward}
                hasMore={pagination.hasAfter}
                loader={<LoadingMore />}
              >
                {render(transformData(dataList))}
              </InfiniteScroll>
            )}
          />
        </Choose>
      ) : (
        <Choose>
          <Choose.When condition={isError}>
            <Box py="xxLarge">
              <EmptyState
                callToAction="Atualizar página"
                action={revalidate}
                illustration={<ExclamationIllustration />}
                title="Serviço temporariamente indisponível"
                body="Algum erro aconteceu do nosso lado. Fique tranquilo que logo voltaremos. Tente atualizar a página, ou tente novamente mais tarde."
              />
            </Box>
          </Choose.When>

          <Choose.Otherwise>
            <Dimmer isVisible={true}>
              <Loader />
            </Dimmer>
          </Choose.Otherwise>
        </Choose>
      )}
    </MainContent>
  )
}
