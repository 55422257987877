import { type QueryFunctionContext, useQuery } from '@tanstack/react-query'

import { Endpoints } from '../endpoints'
import { httpClientSales } from '../http-client-sales'
import { handleErrorResponse } from '../utils'
import { QueryStringBuilder } from '../utils/query-string-builder'

const ITEMS_PER_PAGE = 10

export namespace PaymentLayerSales {
  export type PaymentMethods = 'pix' | 'boleto' | 'card'
  export type PaymentBoletoTypes = 'bill_of_exchange' | 'proposal' | 'tuition_payment'
  export type CardTransactionType = 'credit' | 'debit' | 'voucher'
  export type OriginType = 'pos' | 'e-commerce' | 'tef' | 'tap' | 'link'
  export type CardBrand =
    | 'Visa'
    | 'MasterCard'
    | 'AmericanExpress'
    | 'Cabal'
    | 'Ticket'
    | 'Sodexo'
    | 'VR'
    | 'Alelo'
    | 'Hipercard'
    | 'Senff'
    | 'VerdeCard'
    | 'CooperCard'
    | 'GreenCard'
    | 'UpBrasil'
    | 'VeroCard'
    | 'ValeCard'
    | 'SoroCred'
    | 'BanesCard'
    | 'BenVisaVale'
    | 'Elo'
    | 'UnionPay'
    | 'RedeCompras'

  export enum Status {
    'Authorized' = 'authorized',
    'Cancelled' = 'canceled',
    'Chargeback' = 'chargeback',
    'Paid' = 'paid',
    'Declined' = 'declined',
    'Created' = 'created',
    'Expired' = 'expired'
  }
  export enum Situation {
    'Approved' = 'Approved',
    'Authorized' = 'Authorized',
    'Cancelled' = 'Cancelled',
    'PartialCancelled' = 'PartialCancelled',
    'Dispute' = 'Dispute',
    'PartialDispute' = 'PartialDispute',
    'TransactionFail' = 'TransactionFail',
    'PixFullyReturned' = 'PixFullyReturned',
    'PixPartiallyReturned' = 'PixPartiallyReturned'
  }

  export type Origin = {
    serialNumber: string
    type: OriginType
  }

  export type Item = {
    id: string
    createdAt: string
    paymentMethod: PaymentMethods
    cardTransactionType: CardTransactionType
    installments: number | null
    cardBrand: CardBrand | null
    status: Status
    situation: Situation
    origin: Origin
    amount: 100000
  }

  export type Response = {
    items: Item[]
    size?: 1
    current?: 1
    next?: string
    total?: 3
  }

  export interface BaseBucket {
    name: PaymentMethods | CardTransactionType | PaymentBoletoTypes
    id: string | null
    quantity: number
    amount: number
    netAmount: number
    updatedAmount: number
    avgUpdatedAmount: number | null
    avgAmount: number | null
    canceledAmount: number
    chargebackAmount: number
    chargebackRefundAmount: number
    feeAmount: number
    buckets: Record<string, any>
  }

  export interface CardBrandBucket extends BaseBucket {}

  export interface CardType extends BaseBucket {
    buckets: {
      cardBrand: CardBrandBucket[]
    }
  }

  export interface BucketsTypes extends BaseBucket {
    buckets: {
      cardType: CardType[]
      pixType: BaseBucket[]
      boletoType: BaseBucket[]
    }
  }

  export type TypesBuckets = {
    pixType?: BaseBucket[]
    boletoType?: BaseBucket[]
    cardType?: CardType[]
  }

  export interface Buckets {
    card: BucketsTypes[]
    pix: BucketsTypes[]
    boleto: BucketsTypes[]
  }

  export interface ResumePaymentsDataLayer extends BaseBucket {
    buckets: Buckets
  }

  export interface ResponseResumePaymentsDataLayer extends BaseBucket {
    data: ResumePaymentsDataLayer
  }

  type FilterContent = {
    value: string | number
  }

  export type Filter = {
    selectedPeriodOption: { initialDate: Date; endDate: Date }
    selectedCaptureMethodsOptions: FilterContent[]
    selectedPaymentsOptions: FilterContent[]
    selectedStatusOptions: FilterContent[]
    installments: number | null
    search: string
    deviceNumber: string
    fourNumbers: string
    sixNumbers: string
    brands: FilterContent[]
    prices: { min: number; max: number }
  }

  const generateQuestFilter = (filter: Filter): QueryStringBuilder => {
    const queryString = new QueryStringBuilder()

    if (filter.prices?.min <= filter.prices?.max && filter.prices?.max > 0) {
      queryString.append('MinAmount', Math.max(filter.prices.min * 100, 100))
      queryString.append('MaxAmount', filter.prices.max * 100)
    }
    queryString.append('StartDate', filter.selectedPeriodOption?.initialDate?.toISOString())
    queryString.append('EndDate', filter.selectedPeriodOption?.endDate?.toISOString())
    queryString.append('NumberOfInstallments', filter.installments)
    queryString.append('ChargeId', filter.search)
    queryString.append('TerminalSerialNumber', filter.deviceNumber)
    queryString.append('CardLastFourDigits', filter.fourNumbers)
    queryString.append('CardFirstSixDigits', filter.sixNumbers)

    queryString.append(
      'TerminalTypes',
      (filter.selectedCaptureMethodsOptions ?? []).map(el => el.value)
    )
    queryString.append(
      'PaymentMethodTypes',
      (filter.selectedPaymentsOptions ?? []).map(el => el.value)
    )
    queryString.append(
      'Status',
      (filter.selectedStatusOptions ?? []).map(el => el.value)
    )
    queryString.append(
      'CardBrands',
      (filter.brands ?? []).map(el => el.value)
    )

    if (process.env.NODE_ENV === 'development') {
      queryString.append('proxyDomain', 'https://payments-sales-section-api.platform-k8s-stg.aws.pagarme.run')
    }

    return queryString
  }

  type QueryKeyType = [string, string | undefined, string | null, Filter]

  export const getAllSales = async (ctx: QueryFunctionContext): Promise<Response | undefined> => {
    const [, cnpj, next, filter] = ctx.queryKey as QueryKeyType
    if (!cnpj) return

    const endpoint = Endpoints.sales.getAllPaymentLayerSales(cnpj)
    const queryStringFilter = generateQuestFilter(filter)
    queryStringFilter.append('Size', ITEMS_PER_PAGE)
    queryStringFilter.append('page', next)

    try {
      const response = await httpClientSales.get<Response>(endpoint, {
        params: queryStringFilter.queryString
      })
      return response.data
    } catch (error: unknown) {
      handleErrorResponse(error)
      return undefined
    }
  }

  export function useFetchAllSales(document: string | undefined, next: string | null, filter: Filter) {
    return useQuery<Response | undefined, unknown>({
      queryKey: ['all-sales', document, next, filter] as QueryKeyType,
      queryFn: getAllSales,
      refetchOnWindowFocus: false,
      enabled: !!document,
      cacheTime: 0
    })
  }

  export const getResume = async (
    document: string | undefined,
    filter: Filter
  ): Promise<ResponseResumePaymentsDataLayer | undefined> => {
    if (document === undefined) return undefined
    const endpoint = Endpoints.sales.getPaymentLayerSalesResume(document)
    try {
      const queryString = generateQuestFilter(filter)
      const response = await httpClientSales.get<ResponseResumePaymentsDataLayer>(endpoint, {
        params: queryString.queryString
      })
      return response.data
    } catch (error: unknown) {
      handleErrorResponse(error)
    }
  }
}

export namespace PaymentLayerSaleDetail {
  export type Response = {
    amount: number
    updatedAmount: number
    status: string
    paymentMethod: string
    createdAt: string
    merchant: Merchant
    boletoTransaction: BoletoTransaction | null
    cardTransaction: CardTransaction | null
    pixTransaction: PixTransaction | null
    netAmount: number
    origin: Origin
    situation: string
  }

  export type CardTransaction = {
    type: string
    transactionId: string
    capturedAmount: number
    canceledAmount: number
    chargebackAmount: number
    chargebackRefundAmount: number
    authorizationCode: string
    installments: number
    card: Card
    details: Detail[]
    feeAmount: number
    mdrAmount: number
    mdrValue: number
    mdrRefundAmount: number
    ravValue: number
    ravAmount: number
    ravRefundAmount: number
    canceledDate: any
    canceledRemainingValue: number
  }

  export type Card = {
    brand: string
    firstSixDigits: string
    lastFourDigits: string
  }

  export type Detail = {
    operationId: string
    operation: string
    providerDatetime: string
    operationReason: string
    operationAmount: number
  }

  export interface Origin {
    type: string
    serialNumber: string
  }
  export interface Merchant {
    organization: string
    document: string
    affiliationKey: string
  }

  export type SalesProductsItem = {
    id: string
    name: string
    image: string
    price: number
    quantity: string
  }

  export type BoletoTransaction = {
    type: 'bill_of_exchange' | 'proposal'
    transactionId: string
    ourNumber: string
    fineAmount: number
    interestAmount: number
    feeAmount: number
    expireAt: string
    writableLine: string
    bank: Bank
    fine: Fine
  }

  export type Bank = {
    name: string
  }

  export type Fine = {
    value: string
  }

  export type Terminal = {
    type: string
    serialNumber: string
  }

  export type PixTransaction = {
    transactionId: string
    e2eId: string
    authorizationCode: string
    feeAmount: number
    payer: Payer
    terminal: Terminal
  }

  export type Payer = {
    name: string
    document: string
    institutionName: string
  }

  const getSaleById = async (ctx: QueryFunctionContext): Promise<Response> => {
    const [, cnpj, saleId] = ctx.queryKey

    try {
      const response = await httpClientSales.get<Response>(
        Endpoints.sales.getPaymentLayerSaleById(cnpj as string, saleId as string),

        process.env.NODE_ENV === 'development'
          ? {
              params: {
                proxyDomain: 'https://payments-sales-section-api.platform-k8s-stg.aws.pagarme.run'
              }
            }
          : {}
      )
      return response.data
    } catch (error: unknown) {
      return handleErrorResponse(error)
    }
  }

  export function useFetchSaleById(document: string | null | undefined, saleId: string | undefined) {
    return useQuery<Response, unknown>({
      queryKey: ['sales-by-id', document, saleId],
      queryFn: getSaleById,
      refetchOnWindowFocus: false,
      enabled: !!document && !!saleId
    })
  }
}
