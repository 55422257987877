import { DogIllustration } from 'design-elements/shared/illustrations'

import { EmptyState } from '~/ui/components/utils/empty-state/empty-state'
export function SalesListEmptyState() {
  return (
    <EmptyState
      body="Edite o filtro acima para ver outros períodos."
      illustration={<DogIllustration />}
      title="Você não possui vendas nesse período."
    />
  )
}
