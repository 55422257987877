const InternalServerErrorIllustration = () => {
  return (
    <svg
      viewBox="0 0 118 142"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Ilustração de uma mão apontando com seu dedo polegar para baixo"
    >
      <path
        d="M103.945 83.6752C91.6155 95.6843 72.3666 102.221 75.5881 122.844C76.1228 126.199 76.8019 129.576 76.5025 132.955C76.1175 137.329 73.8718 140.922 68.5944 141.777C66.1897 142.183 63.724 142.033 61.3864 141.338C59.0489 140.642 56.9017 139.421 55.1095 137.767C50.1155 133.198 47.4313 126.539 46.5143 119.82C45.9796 115.88 46.0064 111.891 46.2924 107.934C46.3619 106.982 47.3057 98.831 47.7735 98.1011C47.5917 98.0824 44.0521 97.7777 42.1352 97.6199L41.2663 97.5504L39.5767 97.4194L38.3496 97.3178C35.1094 97.0505 32.0509 96.7537 29.2973 96.4035C25.755 95.9463 22.7046 95.401 20.4134 94.6952C2.58414 89.1985 -1.07047 75.2912 6.53016 64.4851C2.54672 61.5443 -5.56722 51.0136 6.55689 38.8013C3.92354 36.1278 3.28191 31.9706 3.7765 28.2491C4.27109 24.5277 7.70648 9.49752 24.5439 4.58371C41.1193 -0.252566 63.5282 -1.62137 80.4806 2.22305C88.5486 4.09013 96.0242 7.93819 102.232 13.4195C122.344 31.1044 123.127 64.9958 103.945 83.6752Z"
        fill="#E09661"
      />
    </svg>
  )
}

export { InternalServerErrorIllustration }
