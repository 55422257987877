export function SpinnerIllustration({
  width = 40,
  height = 40,
  // TODO: remove fixed color value when removing old design system (ginga). This value was added here to avoid circular dependency.
  fill = '#008E5A',
  ...rest
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M10 2.2c-4.564.927-8 4.962-8 9.8 0 5.523 4.477 10 10 10s10-4.477 10-10a9.96 9.96 0 00-2.4-6.5"
        stroke={fill}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
