export function GiftBoxIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={200} height={200} fill="none" viewBox="0 0 200 200" {...props}>
      <path
        fill="#008E5A"
        d="M136.18 55.907c-.443 5.278-6.065 13.59-18.22 15.148h-14.388c11.23-5.488 17.755-13.775 16.493-20.018-.695-3.434-3.168-6.732-6.263-6.732l13.793-.055c3.337 0 7.047 2.95 8.187 6.903.408 1.417.54 3.04.398 4.755z"
      />
      <path
        fill="#1D232A"
        d="M136.179 55.907l-16.115-4.872c-.325-1.607-1.037-3.195-2.022-4.43-1.123-1.405 9.552-2.355 9.552-2.355 3.338 0 7.048 2.95 8.188 6.903.407 1.417.54 3.04.397 4.755z"
      />
      <path
        fill="#42EC9A"
        d="M111.196 44.25c-7.395 0-12.755 10.928-17.065 26.802h12.037c5.495-11.847 11.213-27.885 22.56-26.692-.417-.097-1.025-.11-1.132-.11h-16.4z"
      />
      <path
        fill="#008E5A"
        d="M92.577 71.055c.745-7.078-2.4-14.5-8.115-19.428a23.366 23.366 0 00-8.855-4.8 23.724 23.724 0 00-4.695-.844s1.153-.136 2.87-.328c4.455-.5 12.703-1.405 14.395-1.405 5.103 0 8.538 2 12.4 7.39.63.877 1.2 1.85 1.713 2.885 2.59 5.203 3.787 12.003 4.28 16.53H92.577z"
      />
      <path
        fill="#1D232A"
        d="M102.289 54.525l-17.827-2.898a23.366 23.366 0 00-8.855-4.8c-.615-.392-1.23-.787-1.828-1.17 4.458-.502 12.705-1.407 14.398-1.407 5.102 0 8.537 2 12.4 7.39.63.877 1.2 1.847 1.712 2.885z"
      />
      <path
        fill="#42EC9A"
        d="M88.177 44.25H74.385c-3.338 0-7.048 2.95-8.188 6.903-1.67 5.787 1.593 17.315 16.925 19.902h16.465c-8.73-4.198-18.395-13.693-17.67-20.018.4-3.502 3.165-6.787 6.26-6.787z"
      />
      <path fill="#00A868" d="M151.647 78.574H48.565v77.093h103.082V78.574z" />
      <path fill="#008E5A" d="M85.715 78.574h-37.15v77.093h37.15V78.574z" />
      <path
        fill="#1D232A"
        d="M55.76 98.096a.37.37 0 01-.35-.248.372.372 0 01.225-.477l20.09-7.238-25.228 7.023a.373.373 0 11-.2-.72L85.2 86.72l.227.71-29.54 10.642a.38.38 0 01-.127.023z"
      />
      <path fill="#1D232A" d="M48.565 95.585l38.982-8.87H48.565v8.87z" />
      <path fill="#00A868" d="M155.462 70.436H44.75v16.282h110.712V70.436z" />
      <path fill="#008E5A" d="M87.545 70.436H44.75v16.282h42.795V70.436z" />
      <path
        fill="#1D232A"
        d="M150.655 87.452H49.05a.373.373 0 010-.745h101.605c.205 0 .373.168.373.373a.374.374 0 01-.373.372z"
      />
      <path fill="#00A868" d="M72.822 70.436H59.404v85.252h13.418V70.436z" />
      <path fill="#42EC9A" d="M126.576 70.436h-13.418v85.252h13.418V70.436z" />
      <path
        fill="#1D232A"
        d="M101.923 54.983c-.012 0-.025 0-.037-.003l-14.643-1.477a.374.374 0 01.073-.745l14.642 1.477a.374.374 0 01-.035.748z"
      />
      <path fill="#42EC9A" d="M110.84 70.436l24.2 24.2 1.027-8.295 8.4-1.13-14.775-14.775H110.84z" />
      <path
        fill="#1D232A"
        d="M132.173 92.146a.378.378 0 01-.265-.11l-20.28-20.28a.374.374 0 010-.53.373.373 0 01.53 0l20.28 20.28a.372.372 0 010 .53.37.37 0 01-.265.11z"
      />
      <path fill="#42EC9A" d="M106.67 70.436v31.632l6.59-5.14 6.742 5.14V70.436H106.67z" />
      <path
        fill="#1D232A"
        d="M120.002 102.442a.368.368 0 01-.227-.077l-6.74-5.14a.374.374 0 11.455-.595l6.14 4.682V71.697a.374.374 0 11.75 0v30.37a.37.37 0 01-.208.335.384.384 0 01-.17.04z"
      />
    </svg>
  )
}
