export function CoinErrorIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={135} height={131} fill="none" {...props}>
      <g clipPath="url(#prefix__clip0_13058_9468)">
        <path
          d="M111.525 61.3a47.394 47.394 0 01-.765 8.422 45.325 45.325 0 01-.673 3.113 42.478 42.478 0 01-1.177 3.915 35.24 35.24 0 01-1.105 2.847c-.225.578-.488 1.15-.758 1.718-.43.902-.87 1.79-1.365 2.67a32.025 32.025 0 01-.912 1.582c-8.24 13.71-23.25 22.883-40.375 22.878l-17.068-.003-.17-53.882-.127-38.438 1.11.033c-.27.622.05 1.525.934 1.525.48 0 .958-.01 1.436-.01-.493.61-.228 1.78.785 1.742l32.262-1.25c.683.308 1.358.625 2.03.968a39.94 39.94 0 013.383 1.88c-10.923.527-21.843 1.06-32.77 1.595-1.35.065-1.328 2.17.034 2.107 11.886-.582 23.786-1.162 35.678-1.747.118.085.243.177.36.265a47.052 47.052 0 0118.33 28.642c.603 3.038.925 6.193.923 9.428z"
          fill="#1D232A"
        />
        <path
          d="M91.45 77.857c-9.198 24.363-36.375 36.693-60.705 27.545a46.814 46.814 0 01-16.445-10.5 47.102 47.102 0 01-9.713-13.87l11.233-8.507a33.548 33.548 0 01-1.363-5.07L2.732 76.337a47.622 47.622 0 01-.887-2.987.8.8 0 00.152-.093l11.723-8.885.165-2.662-1.378 1.06-11.175 8.462a46.483 46.483 0 01-.972-7.197 47.05 47.05 0 012.897-19.093c.03-.072.053-.145.083-.217 6.662-17.648 22.757-28.98 40.372-30.415 1.1-.09 2.203-.14 3.31-.148l17.273-.022.23-.003c3.98.005 7.855.5 11.54 1.435 1.675.425 3.327.94 4.917 1.535.868.328 1.73.683 2.575 1.055l-15.762.61a46.342 46.342 0 015.36 3.005c5.272-.257 10.542-.512 15.815-.767.015.002.027.015.035.02a38.858 38.858 0 012.907 1.935c-5.317.262-10.635.522-15.952.782a46.863 46.863 0 0112.737 14.815c6.375 11.635 7.813 25.89 2.753 39.295z"
          fill="#E8E9EA"
        />
        <path
          d="M24.536 84.502V84.5a32.387 32.387 0 01-7.566-11.64h0a32.458 32.458 0 01-1.142-3.646l47.77-36.18a31.572 31.572 0 013.204 2.082 32.379 32.379 0 019.87 11.926L25.73 85.617a27.228 27.228 0 01-1.194-1.115zM3.807 77.866h0c-.168-.445-.33-.895-.486-1.348l10.801-8.182c.287 1.36.654 2.7 1.103 4.01l-10.44 7.909a46.01 46.01 0 01-.978-2.389z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M45.365 83.075c-1.738 0-3.532-.313-5.303-.948-6.575-2.36-10.034-9.287-9.584-14.62a.375.375 0 01.748.063c-.429 5.05 2.857 11.612 9.091 13.85 4.488 1.612 9.13 1.047 12.416-1.513 2.497-1.945 3.762-4.787 3.47-7.797-.548-5.633-3.993-8.083-8.356-11.183l-.392-.28c-4.358-3.102-7.975-6.057-8.137-10.812-.108-3.11 1.3-5.968 3.862-7.835 3.047-2.223 7.282-2.68 11.33-1.228 4.823 1.733 7.448 6.018 8.295 9.45a.376.376 0 11-.73.18c-.803-3.245-3.278-7.292-7.82-8.922-3.87-1.39-7.748-.98-10.635 1.127-2.357 1.72-3.652 4.345-3.555 7.203.153 4.42 3.485 7.135 7.825 10.227l.392.28c4.338 3.085 8.086 5.75 8.666 11.723.317 3.272-1.05 6.357-3.755 8.462-2.185 1.698-4.925 2.573-7.828 2.573z"
          fill="#1D232A"
        />
        <path
          d="M38.23 86.792a.376.376 0 01-.352-.502l17.887-50.015a.376.376 0 01.707.252L38.585 86.542a.378.378 0 01-.355.25z"
          fill="#1D232A"
        />
        <path
          d="M13.235 64.112L2.142 72.52a35.6 35.6 0 01-.254-1.079l10.921-8.27s0 0 0 0l.509-.39-.083 1.331z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M24.262 85.927a.378.378 0 01-.257-.102 33.935 33.935 0 01-8.537-13.175 33.633 33.633 0 01-.883-19.75c.292-1.145.67-2.333 1.117-3.53a33.794 33.794 0 015.888-9.995.373.373 0 01.527-.043c.158.135.178.37.043.528a33.042 33.042 0 00-5.758 9.775 34.18 34.18 0 00-1.092 3.452 32.925 32.925 0 00.862 19.313 33.162 33.162 0 008.35 12.882.375.375 0 01-.26.645z"
          fill="#1D232A"
        />
        <path
          d="M116.715 36.245l-4.78-13.23-13.233-4.77 13.23-4.78 4.77-13.233 4.78 13.23 13.233 4.77-13.23 4.78-4.77 13.233z"
          fill="#E8E9EA"
        />
        <path
          d="M106.102 55.277l-3.077-8.52-8.52-3.072 8.52-3.078 3.072-8.522 3.078 8.52 8.52 3.072-8.52 3.078-3.073 8.522z"
          fill="#BBBDBF"
        />
      </g>
      <g filter="url(#prefix__filter0_d_13058_9468)">
        <path
          d="M98.535 130.232c15.464 0 28-12.536 28-28s-12.536-28-28-28-28 12.536-28 28 12.536 28 28 28z"
          fill="#4A4F55"
        />
      </g>
      <path
        d="M111.969 111.51a2.94 2.94 0 11-4.157 4.157l-9.277-9.278-9.28 9.278a2.933 2.933 0 01-2.079.86 2.94 2.94 0 01-2.078-5.017l9.28-9.278-9.28-9.28a2.94 2.94 0 014.157-4.157l9.28 9.28 9.277-9.28a2.94 2.94 0 014.157 4.157l-9.277 9.28 9.277 9.278z"
        fill="#FCFCFC"
      />
      <defs>
        <clipPath id="prefix__clip0_13058_9468">
          <path fill="#fff" transform="translate(.285 .232)" d="M0 0h134.43v108.213H0z" />
        </clipPath>
        <filter
          id="prefix__filter0_d_13058_9468"
          x={67.535}
          y={73.232}
          width={62}
          height={62}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={1.5} />
          <feColorMatrix values="0 0 0 0 0.290196 0 0 0 0 0.309804 0 0 0 0 0.333333 0 0 0 0.32 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_13058_9468" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_13058_9468" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
