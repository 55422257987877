import { httpClientBankingV2 } from '~/domains/banking/shared/infra/http-client-banking-v2'
import { useQueryRequest } from '~/ui/hooks/utils/service'

import { type GetHomeBff } from '../../types'

interface GetHome {
  document?: string
  accountId?: string
  globalId?: string
  affiliationKey?: string
}

export async function getHome({ document, accountId, globalId, affiliationKey }: GetHome) {
  return httpClientBankingV2<GetHomeBff>({
    url: '/bff-home/web',
    method: 'POST',
    data: {
      user: {
        plataform: 'web',
        document,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      global: {
        id: globalId,
        account: {
          id: accountId
        },
        affiliationKey: affiliationKey
      }
    }
  })
}

export function useGetHome({ document, accountId, globalId, affiliationKey }: GetHome) {
  const { data: response, ...restQuery } = useQueryRequest<GetHomeBff>(
    ['getBffHome', document, accountId, globalId, affiliationKey],
    () => getHome({ document, accountId, globalId, affiliationKey })
  )

  const data = response?.data || ({} as GetHomeBff)

  return {
    data: data,
    alerts: data.components?.alerts,
    cardsFooter: data.components?.cardsFooter,
    menu: { data: data.components?.sidebar?.menu, id: data.components?.sidebar.id },
    modal: data.components?.modal,
    balance: data.components?.balance,
    header: data.components?.header,
    statement: data.components?.statement,
    salesCard: data.components?.salesCard,
    receivablesCard: data.components?.receivablesCard,
    creditBanner: data.components?.creditBanner,
    ...restQuery
  }
}
