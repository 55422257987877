import { type Dispatch, type ReactNode, type SetStateAction, createContext, useContext, useState } from 'react'
import { type Stores } from 'swh/shared/http'

export type SwhConfigStore = {
  store: Stores.Item
  affiliationKey: string | null
  merchant?: { document: string | null }
}

export const swhConfigStoreDefault: SwhConfigStore = {
  store: {
    id: '',
    name: '',
    document: '',
    stateRegistration: '',
    cnae: ''
  },
  merchant: { document: '' },
  affiliationKey: null
}

const SwhConfigContext = createContext<[state: SwhConfigStore, setState: Dispatch<SetStateAction<SwhConfigStore>>]>([
  {} as SwhConfigStore,
  () => {}
])

export const SwhConfigContextProvider = ({ children }: { children: ReactNode }) => {
  const value = useState<SwhConfigStore>({} as SwhConfigStore)
  return <SwhConfigContext.Provider value={value}>{children}</SwhConfigContext.Provider>
}

export const useSwhConfigContext = () => useContext(SwhConfigContext)

export const useSwhConfig = (): SwhConfigStore => {
  const [state] = useContext(SwhConfigContext)
  return state || swhConfigStoreDefault
}
