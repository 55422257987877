export function CircleInfoWarningBackgroundIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={96} height={96} fill="none" {...props}>
      <path
        d="M0 48C0 21.49 21.49 0 48 0s48 21.49 48 48-21.49 48-48 48S0 74.51 0 48z"
        fill="#FF6C03"
        fillOpacity={0.095}
      />
      <mask
        id="prefix__a"
        style={{
          maskType: 'alpha'
        }}
        maskUnits="userSpaceOnUse"
        x={32}
        y={32}
        width={32}
        height={32}
      >
        <path
          d="M46.35 53.333c0-.902.732-1.634 1.634-1.634h.03a1.633 1.633 0 010 3.267h-.03a1.633 1.633 0 01-1.633-1.633zM49.333 42.666a1.333 1.333 0 00-2.667 0v5.333a1.333 1.333 0 002.667 0v-5.333z"
          fill="#000"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.666 48c0-7.365 5.97-13.334 13.333-13.334s13.334 5.969 13.334 13.333c0 7.363-5.97 13.334-13.334 13.334s-13.333-5.97-13.333-13.334zm13.333-10.667c-5.891 0-10.666 4.775-10.666 10.666 0 5.89 4.775 10.667 10.666 10.667 5.89 0 10.667-4.776 10.667-10.667S53.89 37.333 47.999 37.333z"
          fill="#000"
        />
      </mask>
      <g mask="url(#prefix__a)">
        <path fill="#BC4800" fillOpacity={0.98} d="M32 32h32v32H32z" />
      </g>
    </svg>
  )
}
