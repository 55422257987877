import { divideByOneHundred, multiplyByOneHundred } from '../math'

export const rateUtils = {
  /**
   * Formats a decimal RATE(%) number to a displayable text, showing a fixed(2) amount of decimal places only when necessary.
   *
   * Use esta função quando você precisa formatar uma taxa percentual para exibição,
   * limitando o número de casas decimais a, no máximo, duas. É útil para apresentar
   * taxas em relatórios ou telas com restrições de layout.
   *
   * @param {number} amount - The decimal RATE(%) number to format.
   * @returns {string} The formatted rate with up to two decimal places.
   *
   * @example
   * // Quando a API retorna uma taxa como 0.1234 (12.34%)
   * // Returns "12,34"
   * rateUtils.formatToLimitedDecimalPlaces(0.1234);
   *
   * @example
   * // Quando a taxa é muito pequena, como 0.01 (1%)
   * // Returns "1"
   * rateUtils.formatToLimitedDecimalPlaces(0.01);
   */
  formatToLimitedDecimalPlaces(amount: number): string {
    const normalizedAmount = multiplyByOneHundred(amount)
    const amountWithCutOutDecimalPlaces = divideByOneHundred(Math.floor(multiplyByOneHundred(normalizedAmount)))

    return amountWithCutOutDecimalPlaces
      .toFixed(2)
      .replace('.', ',')
      .replace(/[.,]00$/, '')
  },

  /**
   * Formats a decimal RATE(%) number to a displayable text, showing ALL the decimal places.
   *
   * Use esta função quando for necessário mostrar uma taxa percentual com todas as casas
   * decimais, por exemplo, em cenários onde precisão total é necessária (como em cálculos financeiros).
   *
   * @param {number} amount - The decimal RATE(%) number to format.
   * @param {boolean} [multiply=true] - Whether to multiply the amount by 100 before formatting.
   * @returns {string} The formatted rate with all decimal places preserved.
   *
   * @example
   * // Quando a API retorna uma taxa como 0.1234 (12.34%)
   * // Returns "12,34"
   * rateUtils.formatKeepingAllDecimalPlaces(0.1234);
   *
   * @example
   * // Quando a taxa já está como 0.1234 e não deve ser multiplicada.
   * // Returns "0,1234"
   * rateUtils.formatKeepingAllDecimalPlaces(0.1234, false);
   */
  formatKeepingAllDecimalPlaces(amount: number, multiply = true): string {
    return Number(multiply ? multiplyByOneHundred(amount) : amount)
      .toString()
      .replace('.', ',')
  },

  /**
   * Converts a RATE(%) number to its fractional equivalent.
   *
   * Use esta função quando você precisa converter uma taxa percentual (12.34%)
   * para sua forma decimal (0.1234). Ideal para cálculos em sistemas que trabalham
   * com frações em vez de porcentagens.
   *
   * @param {number} amount - The RATE(%) number to convert.
   * @returns {number} The fractional equivalent of the rate.
   *
   * @example
   * // Quando a API retorna uma taxa como 12.34% (12.34)
   * // Returns 0.1234
   * rateUtils.toFraction(12.34);
   *
   * @example
   * // Quando a taxa é 1% (1)
   * // Returns 0.01
   * rateUtils.toFraction(1);
   */
  toFraction(amount: number): number {
    return divideByOneHundred(amount)
  },

  /**
   * Converts a cents value to a decimal number with two fixed decimal places.
   *
   * Use esta função quando a API retorna valores em centavos e você precisa convertê-los
   * para valores decimais formatados. Por exemplo, um valor de 1234 centavos será exibido
   * como 12.34.
   *
   * @param {number} amount - The cents value to convert.
   * @returns {string} The converted decimal number as a string with two decimal places.
   *
   * @example
   * // Quando a API retorna um valor como 1234 (centavos).
   * // Returns "12.34"
   * rateUtils.centsToDecimal(1234);
   *
   * @example
   * // Quando a API retorna um valor pequeno, como 10 centavos.
   * // Returns "0.10"
   * rateUtils.centsToDecimal(10);
   */
  centsToDecimal(amount: number): string {
    return divideByOneHundred(amount).toFixed(2)
  }
}
