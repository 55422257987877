// eslint-disable-next-line @nx/enforce-module-boundaries
import { Endpoints } from '../endpoints'
import { httpClient } from '../http-client'
import { handleErrorResponse } from '../utils'

import { type SwhResponse } from './types'
const ITEMS_PER_PAGE = 25

export namespace Clients {
  export type Item = {
    id: string
    documentType: 'cpf' | 'cnpj'
    document: string
    name: string
    email: string
    salesAmount: number
    averageTicket: number
    totalSpent: number
    createdAt: string
    lastSaleAt: string
  }

  export type RequestQueryString = Partial<{
    cursor: string | null
    pageSize: number
  }>

  export type ItemsResponse = SwhResponse<Item>

  export const getAll = async (storeId: string, params?: RequestQueryString): Promise<ItemsResponse> => {
    try {
      const response = await httpClient.get<ItemsResponse>(Endpoints.clients.getAll(storeId), {
        params: { ...params, pageSize: ITEMS_PER_PAGE }
      })
      return response.data
    } catch (error: unknown) {
      return handleErrorResponse(error)
    }
  }
}
