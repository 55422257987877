import { stringify } from 'qs'
import { z } from 'zod'

import { Endpoints } from '../endpoints'
import { httpClient } from '../http-client'

import { type SwhResponse } from './types'
const ITEMS_PER_PAGE = 25

type SchemaProps = {
  isPublic: boolean
}

const singleTaxSchema = z.string().optional().nullable()

const taxSchema = z.object({
  cBenef: singleTaxSchema,
  cest: singleTaxSchema,
  cfop: singleTaxSchema,
  codeIpi: singleTaxSchema,
  csosn: singleTaxSchema,
  cstCofins: singleTaxSchema,
  cstIpi: singleTaxSchema,
  cstPis: singleTaxSchema,
  legalIcms: singleTaxSchema,
  legalIpi: singleTaxSchema,
  ncm: singleTaxSchema,
  quoteCofins: singleTaxSchema,
  quoteIcms: singleTaxSchema,
  quoteIcmsDif: singleTaxSchema,
  quoteIcmsSt: singleTaxSchema,
  quoteIpi: singleTaxSchema,
  quotePis: singleTaxSchema,
  reduceIcms: singleTaxSchema,
  reduceIcmsSt: singleTaxSchema
})

const getPriceSchema = ({ isPublic }: SchemaProps) =>
  z
    .object({
      sale: z.coerce.number()
    })
    .optional()
    .nullable()
    .refine(
      input => {
        const price = input?.sale || 0
        return !isPublic || price > 0
      },
      {
        message: 'Preço não pode estar vazio'
      }
    )

const variationsOptionsSchema = z.object({ variationOptionId: z.string(), variationTypeId: z.string() })

export const getVariantSchema = ({ isPublic }: SchemaProps) =>
  z.object({
    tax: z.array(taxSchema).optional().nullable(),
    sku: z.string().optional().nullable(),
    stock: z.object({ current: z.number().int() }).optional().nullable(),
    variationsOptions: z.array(variationsOptionsSchema).optional().nullable(),
    price: getPriceSchema({ isPublic }),
    gtin: z
      .string()
      .optional()
      .nullable()
      .refine(
        input => {
          const value = input?.toString()?.length || 0
          const size = input?.toString()?.replace(/\D/g, '')?.length || 0
          return value === 0 || size === 8 || size === 12 || size === 13 || size === 14
        },
        {
          message: 'Código inválido'
        }
      )
  })

export const getRequestSchema = ({ isPublic }: SchemaProps) => {
  return z.object({
    name: z.string().min(1, 'Nome não pode ser vazio'),
    status: z.nativeEnum(ProductCatalog.Status),
    categoryId: z.string().optional().nullable(),
    subcategoryId: z.string().optional().nullable(),
    variationTypeIds: z.array(z.string()).optional().nullable(),
    variants: z.array(getVariantSchema({ isPublic })).default([])
  })
}

export namespace ProductCatalog {
  export enum Status {
    Draft = 'draft',
    Archived = 'archived',
    Public = 'public'
  }

  export enum StatusLabel {
    draft = 'Rascunho',
    archived = 'Arquivado',
    public = 'Público'
  }

  const schema = getRequestSchema({ isPublic: false })
  export type ItemRequest = z.infer<typeof schema>

  const variantSchema = getVariantSchema({ isPublic: false })
  type Variant = z.infer<typeof variantSchema>

  export type RequestQueryString = Partial<{
    categoryId: string[]
    categoryName?: string
    cursor: string | null
    productName: string
    orderBy: string
    orderDirection: string
    pageSize: number
    status: string[]
    subcategoryId: string
    subcategoryName?: string
    withoutSubcategory: boolean
    gtin?: string
  }>

  type VariationTypes = {
    id: string
    name: string
    existentVariationOptions: Array<{ id: string; name: string }>
  }

  export type Item = {
    id: string
    name: string
    description: string
    status: Status
    category: { id: string; name: string }
    subcategory?: { id: string; name: string }
    variationsTypes?: VariationTypes[]
    variants: Variant[]
  }

  type ItemResponse = { id: string }
  export type ItemsResponse = SwhResponse<Item>

  export const get = async (storeId: string, params?: RequestQueryString): Promise<ItemsResponse> => {
    try {
      if (params?.productName) {
        Object.assign(params, {
          categoryName: params.productName,
          subcategoryName: params.productName,
          gtin: params.productName
        })
      }
      const response = await httpClient.get<ItemsResponse>(Endpoints.products.all(storeId), {
        params: { ...params, pageSize: ITEMS_PER_PAGE },
        paramsSerializer: params => stringify(params, { arrayFormat: 'repeat' })
      })
      return response.data
    } catch (error: any) {
      console.error('Erro ao buscar produtos', error)
      throw error.response || error
    }
  }

  export const getById = async (storeId: string, productId: string): Promise<Item> => {
    try {
      const response = await httpClient.get<Item>(Endpoints.products.id(storeId, productId))
      return response.data
    } catch (error: any) {
      console.error('Erro ao buscar produto', error)
      throw error.response || error
    }
  }

  export const create = async (storeId: string, product: ItemRequest): Promise<ItemResponse> => {
    const response = await httpClient.post<ItemResponse>(Endpoints.products.all(storeId), product)
    return response.data
  }

  export const update = async (storeId: string, productId: string, product: ItemRequest): Promise<ItemResponse> => {
    const response = await httpClient.put<ItemResponse>(Endpoints.products.id(storeId, productId), product)
    return response.data
  }

  export const updateStatus = async (storeId: string, productId: string, status: Status) => {
    const response = await httpClient.put<ItemResponse>(Endpoints.products.status(storeId, productId), { status })
    return response.data
  }
}
