import { type ExperimentValues } from '@stone-payments/experiment/src/lib/types'
import { SWH_ROUTES_INVOICE_SETTINGS, SWH_URL_INVOICE_SETTINGS, SwhExperimentFlags } from 'swh/shared/utils'

import { Heimdall } from '~/domains/platform/core/heimdall'
import { appEnv } from '~/lib/helpers'

import analitica from './analitica'

export function miscellaneousProfileMenu(expData: ExperimentValues | undefined) {
  const swhExperimentFlags = new SwhExperimentFlags(expData)
  const hasDelayPaymentsAccess = Heimdall.pass(['delay_payments_v1_pf', 'delay_payments_v1_pj'], 'some')

  return [
    {
      order: 2,
      label: 'Informe de rendimentos',
      icon: 'file-folded-outline',
      show: true,
      onClick: () => {
        analitica.events.profileMenu.click('informe de rendimentos')
      },
      path: '/informe-de-rendimentos',
      active: (route: string) => ['/settings/report-income', '/informe-de-rendimentos'].includes(route)
    },
    {
      order: 3,
      label: 'Contratos',
      icon: 'percent-outline',
      show: true,
      onClick: () => {
        analitica.events.profileMenu.click('contratos')
      },
      path: '/contratos',
      active: (route: string) => ['/settings/contracts', '/contratos'].includes(route)
    },
    {
      order: 6,
      label: hasDelayPaymentsAccess ? 'Taxas Inteligentes' : 'Taxas e tarifas',
      icon: 'money-coin-outline',
      show: true,
      onClick: () => {
        if (hasDelayPaymentsAccess) {
          analitica.events.delayPayments.click()
          analitica.events.profileMenu.click('taxas inteligentes')
        } else {
          analitica.events.profileMenu.click('taxas e tarifas')
        }
      },
      path: hasDelayPaymentsAccess ? '/taxas-inteligentes' : '/tarifas',
      active: (route: string) =>
        ['/settings/fees', '/tarifas', '/investment/delay-payments/home', '/taxas-inteligentes'].includes(route),
      isNew: hasDelayPaymentsAccess
    },
    {
      order: 9,
      label: 'Central de ajuda',
      icon: 'round-help-outline',
      show: Boolean(appEnv.isProduction),
      onClick: () => {
        analitica.events.profileMenu.click('central de ajuda')

        window.open('https://ajuda.stone.com.br', '_blank')
      }
    },
    {
      order: 25,
      label: 'Nota fiscal',
      icon: 'file-unfolded-outline',
      show: swhExperimentFlags.checkActiveFlagByPathname(SWH_URL_INVOICE_SETTINGS),
      onClick: () => {},
      path: SWH_URL_INVOICE_SETTINGS,
      active: (route: string) => SWH_ROUTES_INVOICE_SETTINGS.includes(route)
    }
  ]
}
