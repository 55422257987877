import { useToggle } from 'react-use'
import NextLink from 'next/link'
import { Grid, Stack } from '@dlpco/fluid-layout'
import { Heading, Text } from '@dlpco/ginga-stone'
import {
  AppleStoreIllustration,
  GoogleStoreIllustration,
  HandWithCellphoneIllustration
} from 'design-elements/shared/illustrations'

import { Box, Flex, Link } from '~/domains/platform/design-system'
import { useSubscribeEvent } from '~/domains/platform/lib/pub-sub'
import { Alert } from '~/ui/components/modals/alert'
import { List } from '~/ui/components/utils/list'

const ONBOARD_MESSAGES_TO_COMPLETE_KYC = [
  'Abra o aplicativo da Stone e toque em Perfil',
  'Depois, toque em Equipe',
  'Na próxima tela, toque em Completar cadastro e siga as orientações'
]

export function HomeTeamPendingKYCModal() {
  const [isOpen, toggleModal] = useToggle(false)

  useSubscribeEvent('show:team:modal', () => toggleModal())

  return (
    <Alert style={{ padding: '0px' }} isOpen={isOpen} onClose={toggleModal}>
      <Box width="70ch">
        <Stack space="1rem">
          <Flex justifyContent="space-around" alignItems="center" padding="1rem">
            <HandWithCellphoneIllustration />
            <Box maxWidth="40ch">
              <Stack space="1rem">
                <Heading weight="semi" size="large">
                  Complete seu cadastro pra usar esta funcionalidade
                </Heading>

                <List<string>
                  items={ONBOARD_MESSAGES_TO_COMPLETE_KYC}
                  render={(item, idx) => (
                    <Text color="neutral" key={idx}>
                      • {item}
                    </Text>
                  )}
                />
              </Stack>
            </Box>
          </Flex>
          <Box backgroundColor="green" padding="2.5rem">
            <Flex justifyContent="space-between" alignItems="center">
              <Box>
                <Box color="white">
                  <Text weight="semi" inheritColor>
                    Complete seu cadastro pelo Aplicativo
                  </Text>
                </Box>
                <Box color="white">
                  <Text inheritColor>Não tem nosso aplicativo? Baixe agora.</Text>
                </Box>
              </Box>
              <Grid style={{ width: '30ch' }} min="5ch" gutter=".5rem">
                <NextLink href="https://apps.apple.com/br/app/conta-stone/id1438680035" passHref legacyBehavior>
                  <Link target="_blank">
                    <AppleStoreIllustration width="7rem" />
                  </Link>
                </NextLink>
                <NextLink
                  href="https://play.google.com/store/apps/details?id=co.stone.banking.mobile.flagship&hl=pt&gl=US"
                  passHref
                  legacyBehavior
                >
                  <Link target="_blank">
                    <GoogleStoreIllustration width="7rem" />
                  </Link>
                </NextLink>
              </Grid>
            </Flex>
          </Box>
        </Stack>
      </Box>
    </Alert>
  )
}
