import { type QueryFunctionContext, useQuery } from '@tanstack/react-query'

import { Endpoints } from '../endpoints'
import { httpClient } from '../http-client'
import { handleErrorResponse } from '../utils'

import { type Invoices } from './invoices'

export namespace SwhSalesDetails {
  export type PaymentMethod = 'credit' | 'debit' | 'pix'
  type Payment = {
    id: string
    method: PaymentMethod
    amount: number
    metadata: {
      itk?: string
      atk?: string
      installmentCount?: number
      brand?: string
      authorizationCode?: string
      authorizationDateTime?: string
      cardHolderName?: string
      accountID?: string
      instantPaymentId?: string
      pan?: string
    }
  }

  export type Response = {
    id: string
    orderId: string
    status: 'finished' | string
    priceTotal: number
    itemsCount: number
    looseValuesCount: number
    createdAt: string
    looseValues: any[]
    invoice: {
      danfeUrl: string
      status: Invoices.Status
    }
    origin: {
      type: string
      serialNumber: string
    }
    items: {
      id: string
      variantId: string
      sku: string
      gtin: string
      name: string
      note: string
      quantity: number
      unitPrice: number
      totalPrice: number
    }[]
    payments: Payment[]
  }

  const getSale = async (ctx: QueryFunctionContext): Promise<Response> => {
    const [, storeId, atk] = ctx.queryKey
    try {
      const response = await httpClient.get<Response>(Endpoints.sales.getSwhSale(storeId as string, atk as string))
      return response.data
    } catch (error: unknown) {
      return handleErrorResponse(error)
    }
  }
  export function useFetchSwhSale(storeId: string | undefined, atk: string | undefined) {
    return useQuery<Response, unknown>({
      queryKey: ['sales-swh-by-id', storeId, atk],
      queryFn: getSale,
      refetchOnWindowFocus: false,
      enabled: !!atk && !!storeId
    })
  }
}
