import { useMemo } from 'react'
import { useToggle } from 'react-use'
import { Stack } from '@dlpco/fluid-layout'
import { Button } from '@dlpco/ginga-stone'
import { CalendarSuccessBalloonIllustration, DogIllustration } from 'design-elements/shared/illustrations'

import { useSale } from '~/domains/acquirer/ports/hooks/use-sale'
import { Flex, Text } from '~/domains/platform/design-system'
import { useToast } from '~/domains/platform/layout/toast'
import { Choose, If } from '~/domains/platform/lib/utilities-components'
import { exportFile } from '~/lib/helpers/utils/export-file'
import { cancellationReceipt } from '~/lib/services/acquirer/sales/sales'
import { Alert } from '~/ui/components/modals/alert/alert'
import { ContentModal } from '~/ui/components/modals/content-modal/content-modal'
import { EmptyState } from '~/ui/components/utils/empty-state/empty-state'

import { SaleCanCancelTransaction } from './cancel'
import { SaleInformation, SaleInformationLoader } from './information'
import { LoaderWrapper, WrapperButton } from './styles'

interface SaleDetailProps {
  selectedSale?: { id: string; stoneCode: string }
  email: string
  onClose(): void
}

const TITLE = 'Detalhes da transação'
const CANCEL_TITLE = 'Agende o cancelamento da venda'
const CANCEL_TITLE__BUTTON = 'Cancelar Transação'
const CANCELED_TITLE__BUTTON = 'Cancelamento Agendado'
const CANCELED_PARTIAL_TITLE__BUTTON = 'Cancelamento Parcial Agendado'
const CANCELED_REQUESTED_TITLE__BUTTON = 'Cancelamento Solicitado'

export function SaleDetail({ onClose, selectedSale, email }: SaleDetailProps) {
  const [cancel, toggleCancel] = useToggle(false)
  const [cancelFeedback, toggleCancelFeedback] = useToggle(false)
  const { addToast } = useToast()

  const { data: sale, isFetched: isSaleFetched } = useSale(selectedSale?.id, selectedSale?.stoneCode)

  const statusLowerCase = sale?.status?.toLowerCase()

  const doesSaleExist = Boolean(sale?.id)

  const saleDetailContent = useMemo(() => {
    return {
      onClose: cancel ? toggleCancel : onClose,
      title: cancel ? CANCEL_TITLE : TITLE
    }
  }, [cancel, toggleCancel, onClose])

  const exportCancellationReceipt = async () => {
    try {
      const fileName = 'Comprovante de transação cancelada'

      const { data } = await cancellationReceipt({
        saleId: selectedSale?.id,
        merchantIds: selectedSale?.stoneCode
      })

      await exportFile({
        data,
        name: `${fileName}.pdf`
      })

      addToast({
        message: 'Você baixou o comprovante!',
        type: 'success'
      })
    } catch (error) {
      addToast({
        message: 'Não foi possível baixar o comprovante. Entre em contato com a gente.',
        type: 'error'
      })
    }
  }

  const cancelButtonStatus = (receivedStatus: string) => {
    if (receivedStatus === 'cancelada') {
      return CANCELED_TITLE__BUTTON
    }
    if (receivedStatus === 'cancelada parcialmente') {
      return CANCELED_PARTIAL_TITLE__BUTTON
    }
    if (receivedStatus === 'cancelamento solicitado') {
      return CANCELED_REQUESTED_TITLE__BUTTON
    }
    return CANCEL_TITLE__BUTTON
  }

  const cancelButtonText = sale ? cancelButtonStatus(statusLowerCase as string) : CANCEL_TITLE__BUTTON

  const disabledCancelIfStatusIs = [
    'cancelamento solicitado',
    'chargeback',
    'chargeback estorno',
    'chargeback parcial',
    'cancelada',
    'cancelada parcialmente'
  ]

  const cancellationReceiptVisible = ['cancelada', 'cancelada parcialmente']

  const cancellationVisible = (status: string[], receivedStatus: string) => {
    return status.some(item => item === receivedStatus)
  }

  const disabledButton = sale && cancellationVisible(disabledCancelIfStatusIs, statusLowerCase as string)
  const affiliationCode = selectedSale?.stoneCode

  return (
    <ContentModal
      isOpen={Boolean(selectedSale)}
      onClose={saleDetailContent.onClose}
      title={saleDetailContent.title}
      headerPosition="left"
      style={{ zIndex: 300 }}
      fullSize
      toolbar={
        <If condition={Boolean(!cancel && doesSaleExist)}>
          <WrapperButton>
            <Button
              disabled={disabledButton}
              color={disabledButton ? 'neutral' : 'negative'}
              variant={disabledButton ? 'filled' : 'contentOnly'}
              size="large"
              onClick={toggleCancel}
            >
              {cancelButtonText}
            </Button>

            <If condition={Boolean(sale && cancellationVisible(cancellationReceiptVisible, statusLowerCase as string))}>
              <Button size="large" onClick={exportCancellationReceipt}>
                Baixar Comprovante
              </Button>
            </If>
          </WrapperButton>
        </If>
      }
    >
      {!doesSaleExist ? (
        <EmptyState illustration={<DogIllustration />} title="Nenhuma venda encontrada" />
      ) : isSaleFetched && affiliationCode && sale ? (
        <Choose>
          <Choose.When
            condition={cancel}
            render={() => (
              <SaleCanCancelTransaction
                email={email}
                sale={{ ...sale, affiliationCode }}
                back={saleDetailContent.onClose}
                onBackToSales={() => onClose()}
              />
            )}
          />
          <Choose.Otherwise render={() => <SaleInformation sale={sale} />} />
        </Choose>
      ) : (
        <LoaderWrapper>
          <SaleInformationLoader />
        </LoaderWrapper>
      )}

      <Alert
        disablePortal
        isOpen={cancelFeedback}
        toolbar={
          <Flex justifyContent="center">
            <Button size="large" data-testid="agree-wait-cancel" onClick={toggleCancelFeedback}>
              Ok entendi
            </Button>
          </Flex>
        }
      >
        <Flex justifyContent="center" alignItems="center" height="100%">
          <Stack space="2rem">
            <Flex justifyContent="center">
              <CalendarSuccessBalloonIllustration width={80} height={80} />
            </Flex>
            <Flex justifyContent="center">
              <Text fontSize="large" color="darkGray" fontWeight="bold">
                Pronto!
              </Text>
            </Flex>
            <Text fontSize="normal" color="mediumGray2" textAlign="center" maxWidth="50ch">
              O agendamento do cancelamento da sua transação foi realizado com sucesso. Ela aparecerá como cancelada nas
              primeiras horas do próximo dia.
            </Text>
          </Stack>
        </Flex>
      </Alert>
    </ContentModal>
  )
}
