import styled from 'styled-components'
const Svg = styled.svg``
const Path = styled.path``

const ArrowNorthWest = () => {
  return (
    <Svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Ilustração de uma seta apontando para cima"
    >
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L7 8.41421V14C7 14.5523 6.55229 15 6 15C5.44771 15 5 14.5523 5 14V6C5 5.44771 5.44771 5 6 5H14C14.5523 5 15 5.44771 15 6C15 6.55229 14.5523 7 14 7H8.41421L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071Z"
      />
    </Svg>
  )
}

export { ArrowNorthWest }
