import getConfig from 'next/config'

export const AMPLITUDE_API_KEY = runtimeEnv('AMPLITUDE_API_KEY')
export const API_MOCKING = runtimeEnv('API_MOCKING')
export const APP_ENV = runtimeEnv('APP_ENV')
export const APP_VERSION = runtimeEnv('APP_VERSION')
export const BANKING_API_ENDPOINT = runtimeEnv('BANKING_API_ENDPOINT')
export const BANKING_API_ENDPOINT_V2 = runtimeEnv('BANKING_API_ENDPOINT_V2')
export const BANKING_API_ENDPOINT_V3 = runtimeEnv('BANKING_API_ENDPOINT_V3')
export const CREDIT_API_ENDPOINT = runtimeEnv('CREDIT_API_ENDPOINT')
export const CREDIT_INSURANCE_API_ENDPOINT = runtimeEnv('CREDIT_INSURANCE_API_ENDPOINT')
export const CUSTOMER_API_ENDPOINT = runtimeEnv('CUSTOMER_API_ENDPOINT')
export const CUSTOMER_API_ENDPOINT_V1 = runtimeEnv('CUSTOMER_API_ENDPOINT_V1')
export const DATADOG_APPLICATION_ID = runtimeEnv('DATADOG_APPLICATION_ID')
export const DATADOG_CLIENT_TOKEN = runtimeEnv('DATADOG_CLIENT_TOKEN')
export const DEPLOY_TYPE = runtimeEnv('DEPLOY_TYPE')
export const DEV_TOOLS = runtimeEnv('DEV_TOOLS')
export const EXPERIMENT_API_KEY = runtimeEnv('EXPERIMENT_API_KEY')
export const HOTJAR_ID = runtimeEnv('HOTJAR_ID')
export const INSURANCE_BFF_API_ENDPOINT = runtimeEnv('INSURANCE_BFF_API_ENDPOINT')
export const LOG_API_TOKEN = runtimeEnv('LOG_API_TOKEN')
export const NODE_ENV = runtimeEnv('NODE_ENV')
export const OPEN_FINANCE_API_ENDPOINT = runtimeEnv('OPEN_FINANCE_API_ENDPOINT')
export const PAGAR_ME_API_ENDPOINT = runtimeEnv('PAGAR_ME_API_ENDPOINT')
export const PAYMENTS_RECEIVABLES_BFF = runtimeEnv('PAYMENTS_RECEIVABLES_BFF')
export const PORTAL_API_ENDPOINT = runtimeEnv('PORTAL_API_ENDPOINT')
export const PORTS__TWILIO__CUSTOM_API_ENDPOINT = runtimeEnv('PORTS__TWILIO__CUSTOM_API_ENDPOINT')
export const PUBLIC_GOOGLE_ANALYTICS = runtimeEnv('PUBLIC_GOOGLE_ANALYTICS')
export const RAV_API_ENDPOINT = runtimeEnv('RAV_API_ENDPOINT')
export const RECAPTCHA_SITE_KEY = runtimeEnv('RECAPTCHA_SITE_KEY')
export const SALES_SECTION_API_ENDPOINT = runtimeEnv('SALES_SECTION_API_ENDPOINT')
export const SWH_API_ENDPOINT = runtimeEnv('SWH_API_ENDPOINT')
export const SWH_DEV_DOCUMENT = runtimeEnv('SWH_DEV_DOCUMENT')
export const SWH_MODE = runtimeEnv('SWH_MODE')
export const TWILIO__ACCOUNT_S_ID = runtimeEnv('TWILIO__ACCOUNT_S_ID')
export const TWILIO__FLEX_FLOW_S_ID = runtimeEnv('TWILIO__FLEX_FLOW_S_ID')

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig()

function runtimeEnv(key: string): () => string {
  return () => {
    let source = {} as NodeJS.ProcessEnv

    if (typeof window === 'undefined' || process?.env?.NODE_ENV === 'test') {
      source = serverRuntimeConfig
    } else {
      source = publicRuntimeConfig
    }

    return source[key] as string
  }
}

export { runtimeEnv }
