export function ConfirmSaleIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 140 140" width="140" height="140" {...props}>
      <g clipPath="url(#clip0_8888_73065)">
        <path
          d="M89.9401 106.49C88.7275 109.342 87.0314 111.542 84.72 112.74C83.7006 112.06 82.5548 111.484 81.3008 110.993C78.9587 110.075 76.2431 109.443 73.2623 108.945C72.8401 108.877 72.4142 108.808 71.9812 108.743C71.577 108.682 71.1674 108.623 70.7542 108.565C67.5064 108.112 64.0059 107.762 60.3827 107.336C51.9599 106.349 43.4 105.681 35.1558 101.993C26.2693 98.0182 25.5151 89.4926 25.5277 87.4085C25.5385 85.3245 26.2062 83.0871 27.8571 81.8168C22.1138 74.1843 27.3718 69.0112 29.786 67.6922C26.4263 61.1712 29.4883 53.7985 39.7082 52.1277C41.0218 51.913 42.7396 51.8426 44.7226 51.8607C46.72 51.8805 48.9899 51.9906 51.3915 52.1349C52.1476 52.1818 54.8902 52.3208 55.0111 52.3262C54.809 51.8877 54.9064 47.3353 54.9407 46.8066C55.0833 44.6089 55.372 42.413 55.9638 40.2874C56.9743 36.6679 58.9555 33.2035 62.0481 31.0707C64.318 29.5063 67.095 29.0733 69.7654 29.8925C72.6019 30.7622 73.5654 32.9094 73.4463 35.3417C73.3543 37.2236 72.721 39.028 72.1779 40.8324C71.4182 43.3476 71.0447 45.9658 71.1891 48.5911C71.5734 55.5722 75.4979 60.8536 86.6002 63.8597C86.934 63.9499 90.052 64.4552 90.079 64.5923C92.1703 75.0414 94.5954 95.5589 89.9401 106.49Z"
          fill="#FFCBD9"
        />
        <path
          d="M37.9217 72.7068C37.909 72.7068 37.8982 72.7068 37.8856 72.705C34.091 72.1907 31.2509 70.4748 29.4465 67.6058C29.3671 67.4795 29.405 67.3117 29.5313 67.2323C29.6576 67.1529 29.8254 67.1908 29.9048 67.3171C31.619 70.0435 34.3292 71.6765 37.9577 72.1672C38.1057 72.1871 38.2104 72.3242 38.1905 72.4722C38.1707 72.6075 38.0534 72.7068 37.9217 72.7068Z"
          fill="#1D232A"
        />
        <path
          d="M35.1574 85.6405C35.1538 85.6405 35.1502 85.6405 35.1466 85.6405C32.0539 85.5214 29.461 84.2583 27.6477 81.9848C27.5538 81.8675 27.5737 81.6979 27.691 81.6041C27.8082 81.5102 27.9779 81.5301 28.0717 81.6474C29.784 83.7928 32.238 84.9855 35.1701 85.0991C35.3198 85.1046 35.4353 85.2309 35.4299 85.3806C35.4209 85.5268 35.3018 85.6405 35.1574 85.6405Z"
          fill="#1D232A"
        />
        <path
          d="M55.5757 57.0517C55.5505 57.1798 55.4386 57.27 55.3105 57.27C55.2942 57.27 55.2762 57.2682 55.2582 57.2646C51.7811 56.5861 47.3659 56.3083 47.3225 56.3047C47.1728 56.2956 47.0591 56.1675 47.0681 56.0178C47.0772 55.8698 47.2071 55.7579 47.355 55.7652C47.3983 55.767 51.3734 56.016 54.7764 56.624L51.3914 52.1348C52.1474 52.1817 54.8901 52.3206 55.0092 52.3278C55.2185 54.2766 55.4621 56.1134 55.5739 56.9362V56.9416C55.5829 56.9759 55.5829 57.0138 55.5757 57.0517Z"
          fill="#1D232A"
        />
        <path
          d="M90.4037 86.8528L89.94 106.49C88.7275 109.342 87.0314 111.542 84.72 112.74C83.7005 112.06 82.5547 111.484 81.3007 110.993C78.9586 110.075 76.243 109.443 73.2622 108.945C77.627 104.747 85.3298 96.5711 90.4037 86.8528Z"
          fill="#1D232A"
        />
        <path
          d="M87.7387 91.2991C82.9517 98.0564 75.6927 105.236 71.9811 108.744C71.5769 108.682 71.1674 108.623 70.7542 108.565C74.2185 105.319 81.9521 97.7894 86.9466 90.7379C87.1017 90.5196 87.4049 90.4672 87.6232 90.6224C87.8433 90.7776 87.8939 91.0807 87.7387 91.2991Z"
          fill="#1D232A"
        />
        <path
          d="M89.3682 62.8152C87.5584 79.9062 85.0161 96.961 80.4925 113.924C80.2128 114.976 80.9237 116.031 82.001 116.174L109.817 121.748C110.694 121.784 111.362 121.152 111.656 120.324C116.757 105.934 119.627 84.2547 119.954 66.5159C119.97 65.5921 119.31 64.7964 118.395 64.6719L91.4053 61.2147C90.3985 61.0758 89.4765 61.8011 89.3682 62.8152Z"
          fill="#00A868"
        />
        <path
          d="M32.4004 42.9671L35.6808 33.8875L44.7621 30.6126L35.6826 27.3341L32.4076 18.2509L29.1291 27.3304L20.0459 30.6054L29.1255 33.8857L32.4004 42.9671Z"
          fill="#42EC9A"
        />
        <path
          d="M83.722 51.0994L86.1092 44.4918L92.7168 42.11L86.111 39.7228L83.7274 33.1152L81.3403 39.721L74.7327 42.1046L81.3403 44.4918L83.722 51.0994Z"
          fill="#42EC9A"
        />
        <path
          d="M95.2645 39.3419L96.5727 35.7205L100.194 34.416L96.5745 33.1078L95.2682 29.4865L93.96 33.106L90.3386 34.4124L93.96 35.7205L95.2645 39.3419Z"
          fill="#42EC9A"
        />
      </g>
      <defs>
        <clipPath id="clip0_8888_73065">
          <rect width="99.9084" height="103.498" fill="white" transform="translate(20.0459 18.2509)" />
        </clipPath>
      </defs>
    </svg>
  )
}
