import { Heimdall } from '~/domains/platform/core/heimdall'
import { NightOwl } from '~/domains/platform/core/night-owl/night-owl'
import { type Entity } from '~/domains/platform/infra/deus-ex-machina/types'
import { isomorphicPush } from '~/lib/helpers/utils/isomorphic-push'
import { stringFormat } from '~/lib/helpers/utils/string-format'

import analitica from './analitica'

export function bankingMenuHighlights(entity: Entity) {
  const hasBankingVision = entity.roles.banking
  const isFreemium = entity.kycStatus !== 'approved'
  const isOrg = stringFormat.documentType(entity.document || '') === 'CNPJ'

  return [
    {
      order: 0,
      label: 'Transferir',
      icon: 'transfer-outline',
      show: hasBankingVision,
      onClick: () => {
        if (!isFreemium || (isFreemium && isOrg)) {
          isomorphicPush('/transferir/selecione-contato')
          analitica.events.highlightsMenu.click('transferir')
        }
      },
      isLocked: () => isFreemium && !isOrg
    },
    {
      order: 1,
      label: 'Pagar',
      icon: 'code-bar-scanner-outline',
      show: hasBankingVision,
      onClick: () => {
        if (!isFreemium) {
          isomorphicPush('/pagar')
          analitica.events.highlightsMenu.click('pagar')
          analitica.events.paymentSelect.click('home payment select')
        }
      },
      isLocked: () => isFreemium
    },
    {
      order: 2,
      label: 'Pix',
      icon: 'pix-outline',
      show: hasBankingVision,
      onClick: () => {
        analitica.events.highlightsMenu.click('pix')

        isomorphicPush('/pix')
      }
    }
  ]
}

export async function bankingMenu(entity: Entity) {
  const hasBankingVision = entity.roles.banking
  const isFreemium = entity.kycStatus !== 'approved'
  const hasBarcodePaymentInvoiceProposal = Heimdall.pass(['barcode_payment_invoice_proposal'])
  const hasBoletoIssuanceScope = NightOwl.verifyScopes(['paymentaccount:boletoissuance'])
  const hasOpenFinance = Heimdall.pass(['opf_home_web'])
  const hasCardlessWithdrawal = Heimdall.pass(['cardless_withdrawal'])
  const hasInsurances = Heimdall.pass(['web_insurance_conta_pf', 'web_insurance_conta_pj'], 'some')
  const isNewInvoiceCard = Heimdall.pass(['new_card_home_invoice_web'])
  const hasPayroll = Heimdall.pass(['stonehome_payrollhome_web'])

  return [
    {
      order: 5,
      label: 'Extrato',
      icon: 'extract-outline',
      show: hasBankingVision,
      onClick: () => {
        analitica.events.lateralMenu.click('extrato')
      },
      path: '/extrato',
      active: (route: string) => ['/banking/statement-v2'].includes(route)
    },
    {
      order: 17,
      label: 'Open Finance',
      icon: 'open-finance-solid',
      show: hasOpenFinance && hasBankingVision,
      onClick: () => {
        analitica.events.lateralMenu.click('open-finance')
      },
      path: '/open-finance',
      active: (route: string) => ['/open-finance'].includes(route)
    },
    {
      order: 18,
      label: 'Agendamentos',
      icon: 'calendar-check-outline',
      show: false,
      onClick: () => {
        analitica.events.lateralMenu.click('agendamentos')
      },
      path: '/agenda-financeira',
      active: (route: string) => ['/banking/finance-calendar'].includes(route)
    },
    {
      order: 9,
      label: isNewInvoiceCard ? 'Criação de boletos' : 'Boletos',
      icon: 'code-bar-outline',
      show: hasBarcodePaymentInvoiceProposal && hasBoletoIssuanceScope,
      onClick: () => {
        analitica.events.lateralMenu.click('boletos')
      },
      path: '/boletos',
      active: (route: string) => ['/banking/boletos'].includes(route),
      isLocked: () => isFreemium
    },
    {
      order: 11,
      label: 'Saque',
      icon: 'money-hand-outline',
      show: hasCardlessWithdrawal,
      onClick: () => {
        analitica.events.lateralMenu.click('saque')
      },
      path: '/saque',
      active: (route: string) => route.startsWith('/banking/withdrawal'),
      isLocked: () => isFreemium
    },
    {
      order: 13,
      label: 'Folha de Pagamento',
      icon: 'person-money-outline',
      show: hasPayroll,
      onClick: () => {
        analitica.events.lateralMenu.click('folha-de-pagamento')
      },
      path: '/folha-de-pagamento',
      active: (route: string) => route.startsWith('/banking/payroll')
    },
    {
      order: 15,
      label: 'Seguros',
      icon: 'heart-outline',
      show: hasInsurances,
      onClick: () => {
        analitica.events.lateralMenu.click('seguros')
      },
      path: '/seguros',
      active: (route: string) => ['/sva/insurances/home'].includes(route)
    },
    {
      order: 14,
      label: 'Recarga',
      icon: 'device-smartphone-add-outline',
      show: hasBankingVision,
      onClick: () => {
        analitica.events.lateralMenu.click('recarga')
      },
      path: '/recargas',
      active: (route: string) => ['/sva/topups'].includes(route),
      isLocked: () => isFreemium
    }
  ]
}
