import { useEffect } from 'react'
import { type NextPage } from 'next'
import { SWH_DEV_DOCUMENT, SWH_MODE } from 'shared/envs'
import { Stores } from 'swh/shared/http'

import { withPageConfig } from '~/domains/platform/infra/page-enhancers/with-page-config'

import { useSwhConfigContext } from './swh-config-context'
import { withSwhStoreConfig } from './with-swh-store-config'

export type SwhPageConfig = {
  redirect404IfNotSwh?: boolean
}

export function performanceWrapper<P extends NextPage<any, any>>(Page: P) {
  return function PerformanceWrapper(props: any) {
    const [config, setConfig] = useSwhConfigContext()

    useEffect(() => {
      const effect = async () => {
        // eslint-disable-next-line testing-library/no-await-sync-query
        const store = await Stores.getByDocument(SWH_DEV_DOCUMENT() ?? '')
        const affiliationKey = 'ea6063b875447b88f4dcfebcd7da20e'
        setConfig({
          store: store,
          merchant: { document: SWH_DEV_DOCUMENT() ?? '' },
          affiliationKey: affiliationKey
        })
      }
      effect()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (config?.store?.id === undefined) return null

    return (
      <div className="h-full flex flex-col">
        <div
          className="flex flex-col bg-white w-full h-20"
          style={{ boxShadow: '0px 4px 4px rgba(13, 17, 27, 0.08)', borderTop: '4px solid #00a868', zIndex: 2 }}
        >
          Header
        </div>
        <div className="flex flex-row" style={{ minHeight: '100vh' }}>
          <div
            style={{ width: '16rem', minWidth: 'min(100vw, 16rem)' }}
            className="bg-white flex items-center justify-center"
          >
            sideBar
          </div>
          <div className="w-full">
            <Page {...props} />
          </div>
        </div>
      </div>
    )
  }
}

export function withSwhConfig<P extends NextPage<any, any>, PageConfig extends Record<string, any>>(
  Page: P,
  pageConfig?: PageConfig
) {
  return function WithFeatureFlagDev(props: any) {
    const isProd = process.env.APP_ENV === 'prod'
    const isSWHPerformanceMode = SWH_MODE() === 'true'
    if (isProd) {
      return null
    } else if (isSWHPerformanceMode) {
      const PerformanceWrapper = performanceWrapper(Page)
      const p = { ...pageConfig, ...props }
      return <PerformanceWrapper {...p} />
    } else {
      const Component = withPageConfig(Page, { ...pageConfig, navigation: pageConfig?.navigation ?? 'fullscreen' })
      const WithConfigComponent = withSwhStoreConfig(Component, pageConfig as SwhPageConfig)
      return <WithConfigComponent {...props} />
    }
  }
}
