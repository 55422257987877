const UnauthorizedIllustration = () => {
  return (
    <svg
      viewBox="0 0 98 140"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Ilustração de um cadeado com um ícone em formato de 'x' ao lado"
    >
      <g clipPath="url(#clip0_4594_772)">
        <path
          d="M69.0701 26.93C68.2651 12.16 55.9851 0.382503 41.0101 0.375003C25.5101 0.367503 12.8976 12.9775 12.8901 28.48L12.8726 71.5325L69.0976 71.555L69.1151 28.5025C69.1151 27.9775 69.1001 27.45 69.0701 26.93ZM24.6751 59.74L24.6876 28.485C24.6926 19.4875 32.0076 12.1675 41.0051 12.1725C50.0026 12.1775 57.3226 19.5025 57.3176 28.4975L57.3051 59.7525L24.6751 59.74Z"
          fill="#BBBDBF"
        />
        <path
          d="M69.1151 26.9229L69.0976 71.5554L12.8726 71.5329L12.8876 37.1629L24.6876 28.4854L24.6751 59.7404L57.3051 59.7529L57.3151 37.3404L69.0701 26.9304L69.1151 26.9229Z"
          fill="#1D232A"
        />
        <path
          d="M78.8851 40.8154L78.8576 109.49C78.8576 110.355 78.1501 111.063 77.2851 111.063L1.9475 111.033C1.0825 111.033 0.375 110.325 0.375 109.46L0.4025 40.7854C0.4025 39.9129 1.11 39.2129 1.975 39.2129L77.3126 39.2454C78.1801 39.2429 78.8876 39.9429 78.8851 40.8154Z"
          fill="#BBBDBF"
        />
        <path
          d="M78.8872 39.2429L78.8572 109.49C78.8572 110.355 78.1497 111.063 77.2847 111.063L60.1797 111.055L60.2097 39.2354L78.8872 39.2429Z"
          fill="#BBBDBF"
        />
        <path
          d="M78.8772 62.3252L78.8571 109.49C78.8571 110.355 78.1497 111.063 77.2847 111.063L60.1797 111.055L60.1922 81.8152L78.8772 62.3252Z"
          fill="#1D232A"
        />
        <path d="M60.1929 77.1329L78.8778 59.0679L78.8803 53.5029L60.1954 70.7054L60.1929 77.1329Z" fill="#1D232A" />
        <path
          d="M39.1947 89.7254L21.6248 89.7179C21.0823 89.7179 20.7048 89.1754 20.8848 88.6629L24.1198 79.6679L25.6322 75.4679C23.0923 73.8554 21.4098 71.0079 21.4123 67.7754V67.7054C21.4548 62.7029 25.5147 58.6704 30.5222 58.6729C30.9222 58.6729 31.3172 58.6979 31.7022 58.7529C32.1022 58.8079 32.4972 58.8879 32.8747 58.9804C33.9372 59.2729 34.9272 59.7354 35.7997 60.3579C37.6797 61.6954 39.0297 63.7329 39.4697 66.0854C39.5722 66.6354 39.6272 67.2029 39.6272 67.7854C39.6247 70.8829 38.0822 73.6129 35.7222 75.2629L39.9397 88.7054C40.0997 89.2079 39.7222 89.7254 39.1947 89.7254Z"
          fill="#1D232A"
        />
        <path
          d="M60.1772 109.873C59.9697 109.873 59.8022 109.705 59.8022 109.498L59.8272 47.3252C59.8272 47.1177 59.9947 46.9502 60.2022 46.9502C60.4097 46.9502 60.5772 47.1177 60.5772 47.3252L60.5522 109.5C60.5522 109.705 60.3847 109.873 60.1772 109.873Z"
          fill="#1D232A"
        />
        <g filter="url(#filter0_d_4594_772)">
          <path
            d="M69.625 139.625C85.089 139.625 97.625 127.089 97.625 111.625C97.625 96.161 85.089 83.625 69.625 83.625C54.161 83.625 41.625 96.161 41.625 111.625C41.625 127.089 54.161 139.625 69.625 139.625Z"
            fill="#4A4F55"
          />
        </g>
        <path
          d="M83.0596 120.903C84.207 122.053 84.207 123.913 83.0596 125.06C82.4859 125.634 81.7336 125.921 80.9812 125.921C80.2288 125.921 79.4764 125.634 78.9028 125.06L69.6251 115.782L60.345 125.06C59.7713 125.634 59.019 125.921 58.2666 125.921C57.5142 125.921 56.7618 125.634 56.1882 125.06C55.0408 123.913 55.0408 122.053 56.1882 120.903L65.4682 111.626L56.1882 102.346C55.0408 101.198 55.0408 99.336 56.1882 98.1886C57.3355 97.0413 59.1953 97.0413 60.345 98.1886L69.6251 107.469L78.9028 98.1886C80.0501 97.0413 81.9122 97.0413 83.0596 98.1886C84.207 99.336 84.207 101.198 83.0596 102.346L73.7819 111.626L83.0596 120.903Z"
          fill="#FCFCFC"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_4594_772"
          x="38.625"
          y="82.625"
          width="62"
          height="62"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.290196 0 0 0 0 0.309804 0 0 0 0 0.333333 0 0 0 0.32 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4594_772" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4594_772" result="shape" />
        </filter>
        <clipPath id="clip0_4594_772">
          <rect width="97.25" height="139.25" fill="white" transform="translate(0.375 0.375)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export { UnauthorizedIllustration }
