const BrowserErrorIllustration = () => {
  return (
    <svg
      viewBox="0 0 124 142"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Ilustração de uma tela de um smartphone com um ícone em formato de 'x' ao lado"
    >
      <g clipPath="url(#clip0_4594_855)">
        <path
          d="M0.335036 29.8502L4.52254 46.9877L5.38004 50.4877L5.83504 52.3527L21.9425 118.243C23.4725 124.49 29.7725 128.315 36.02 126.785L81.86 115.578C88.1076 114.055 91.9325 107.753 90.4025 101.505L68.795 13.1177C67.27 6.87274 60.97 3.04525 54.7225 4.57025L30.74 10.4352L8.88254 15.7777C2.63504 17.3077 -1.18996 23.6077 0.335036 29.8502Z"
          fill="#1D232A"
        />
        <path
          d="M0.335035 29.85L4.52253 46.9875L27.2425 26.4025L37.1425 17.425C35.955 16.61 33.56 13.8925 30.7425 10.4375L8.88253 15.7775C2.63503 17.3075 -1.18996 23.6075 0.335035 29.85Z"
          fill="#E8E9EA"
        />
        <path
          d="M5.37988 50.4853L5.83488 52.3503C12.3874 46.4753 18.9374 40.6003 25.4898 34.7253C25.8298 34.4128 25.7148 33.7903 25.4023 33.5178C25.0173 33.1978 24.5423 33.2953 24.1948 33.6053C22.0223 35.5628 19.8324 37.5203 17.6599 39.4728C13.5699 43.1403 9.47737 46.8103 5.37988 50.4853Z"
          fill="#E8E9EA"
        />
        <path
          d="M48.7099 123.68L98.3574 111.543C103.705 110.235 106.98 104.84 105.672 99.4926L83.2699 7.85256C81.9624 2.50505 76.5674 -0.769953 71.2199 0.537548L21.5724 12.6751C16.2249 13.9826 12.9499 19.3776 14.2549 24.7251L36.6574 116.365C37.9674 121.713 43.3624 124.988 48.7099 123.68Z"
          fill="#BBBDBF"
        />
        <path d="M42.95 107.947L96.207 94.9258L76.9541 16.1792L23.697 29.2002L42.95 107.947Z" fill="#E8E9EA" />
        <path
          d="M76.5866 16.7837L95.6021 94.5588L89.5818 96.0307L70.5664 18.2556L76.5866 16.7837Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
        <path
          d="M48.3222 16.9197C49.6656 16.9197 50.7547 15.8306 50.7547 14.4872C50.7547 13.1438 49.6656 12.0547 48.3222 12.0547C46.9787 12.0547 45.8896 13.1438 45.8896 14.4872C45.8896 15.8306 46.9787 16.9197 48.3222 16.9197Z"
          fill="#1D232A"
        />
        <g filter="url(#filter0_d_4594_855)">
          <path
            d="M96 141.75C111.464 141.75 124 129.214 124 113.75C124 98.286 111.464 85.75 96 85.75C80.536 85.75 68 98.286 68 113.75C68 129.214 80.536 141.75 96 141.75Z"
            fill="#4A4F55"
          />
        </g>
        <path
          d="M109.435 123.028C110.582 124.178 110.582 126.038 109.435 127.185C108.861 127.759 108.109 128.046 107.356 128.046C106.604 128.046 105.851 127.759 105.278 127.185L96.0001 117.907L86.72 127.185C86.1463 127.759 85.394 128.046 84.6416 128.046C83.8892 128.046 83.1368 127.759 82.5632 127.185C81.4158 126.038 81.4158 124.178 82.5632 123.028L91.8432 113.751L82.5632 104.471C81.4158 103.323 81.4158 101.461 82.5632 100.314C83.7105 99.1663 85.5703 99.1663 86.72 100.314L96.0001 109.594L105.278 100.314C106.425 99.1663 108.287 99.1663 109.435 100.314C110.582 101.461 110.582 103.323 109.435 104.471L100.157 113.751L109.435 123.028Z"
          fill="#FCFCFC"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_4594_855"
          x="65"
          y="84.75"
          width="62"
          height="62"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.290196 0 0 0 0 0.309804 0 0 0 0 0.333333 0 0 0 0.32 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4594_855" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4594_855" result="shape" />
        </filter>
        <clipPath id="clip0_4594_855">
          <rect width="124" height="141.5" fill="white" transform="translate(0 0.25)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export { BrowserErrorIllustration }
