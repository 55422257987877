type ValidQueryString = string | number | null

export class QueryStringBuilder {
  queryString: URLSearchParams

  constructor() {
    this.queryString = new URLSearchParams()
  }

  append = (key: string, queryValue: ValidQueryString | Array<ValidQueryString>) => {
    const queryStrings = Array.isArray(queryValue) ? queryValue : [queryValue]
    for (const value of queryStrings) {
      if (value === null) return
      if (value === '') return
      if (typeof value === 'string') this.queryString.append(key, value)
      if (typeof value === 'number') this.queryString.append(key, String(value))
    }
  }
}
