export { AccountSwitchIllustration } from './account-switch.illustration'
export { AlertPhoneIllustration } from './alert-phone.illustration'
export { AlgarIllustration } from './algar.illustration'
export { AlgarLandlineIllustration } from './algar-landline.illustration'
export { AppleStoreIllustration } from './apple-store.illustration'
export { AppleStoreBlackIllustration } from './apple-store-black.illustration'
export { ArrowNorthWest } from './arrow-north-west.illustration'
export { ArrowSouthEast } from './arrow-south-east.illustration'
export { type IconProps } from './assets/interface'
export { AvatarPfIllustration } from './avatar-pf.illustration'
export { AvatarPjIllustration } from './avatar-pj.illustration'
export { BrowserErrorIllustration } from './browser-error.illustration'
export { CalendarIllustration } from './calendar.illustration'
export { CalendarCheckedIllustration } from './calendar-checked.illustration'
export { CalendarLoadingIllustration } from './calendar-loading.illustration'
export { CalendarNegativeIllustration } from './calendar-negative.illustration'
export { CalendarSuccessIllustration } from './calendar-success.illustration'
export { CalendarSuccessBalloonIllustration } from './calendar-success-balloon.illustration'
export { CannotLoginThinkingDuoIllustration } from './cannot-login-thinking-duo.illustration'
export { CellphoneIllustration } from './cellphone.illustration'
export { CellphoneApprovedIllustration } from './cellphone-approved.illustration'
export { CellphoneRejectedIllustration } from './cellphone-rejected.illustration'
export { CellphoneStone } from './cellphone-stone.illustration'
export { CircleInfoWarningBackgroundIllustration } from './circle-info-warning-background.illustration'
export { ClaroIllustration } from './claro.illustration'
export { ClaroLandlineIllustration } from './claro-landline.illustration'
export { ClaroTvIllustration } from './claro-tv.illustration'
export { CnpjAvatarIllustration } from './cnpj-avatar.illustration'
export { CoinErrorIllustration } from './coin-error.illustration'
export { CoinFailedIllustration } from './coin-failed.illustration'
export { CoinPaymentInTransactionIllustation } from './coin-payment-in-transaction.illustration'
export { CoinPaymentWaiting } from './coin-payment-waiting.illustration'
export { CoinSuccessIllustration } from './coin-success.illustration'
export { CoinsStackIllustration } from './coins-stack.illustration'
export { CoinsStackErrorIllustration } from './coins-stack-error.illustration'
export { CoinsStackNeutralIllustration } from './coins-stack-neutral.illustration'
export { CoinsStackSuccessIllustration } from './coins-stack-success.illustration'
export { CorreiosIllustration } from './correios.illustration'
export { CpfAvatarIllustration } from './cpf-avatar.illustration'
export { CreditCardIllustration } from './credit-card.illustration'
export { DisbursementConfirmedIllustration } from './disbursement-confirmed.illustration'
export { DocumentCheckedIllustration } from './document-checked.illustration'
export { DocumentSuccessIllustration } from './document-success.illustration'
export { DocumentsGreyAndGreenIllustration } from './documents-grey-and-green.illustration'
export { DocumentsNeutralErrorIllustration } from './documents-neutral-error.illustration'
export { DogIllustration } from './dog.illustration'
export { EmbratelIllustration } from './embratel.illustration'
export { EmptyDocumentIllustration } from './empty-document.illustration'
export { ErrorCloudIllustration } from './error-cloud.illustration'
export { ErrorInComunicationIllustration } from './error-in-comunication.illustration'
export { ExclamationIllustration } from './exclamation.illustration'
export { FailureIllustration } from './failure.illustration'
export { FollowUpBannerLoanCancelledIllustration } from './follow-up-banner-loan-cancelled.illustration'
export { FreemiumAnalysisIllustration } from './freemium-analysis.illustration'
export { FreemiumEmailMissingIllustration } from './freemium-email-missing.illustration'
export { FreemiumInviteBannerIllustration } from './freemium-invite-banner.illustration'
export { FreemiumInviteNotificationIllustration } from './freemium-invite-notification.illustration'
export { FreemiumPartiallyRejectedIllustration } from './freemium-partialy-rejected.illustration'
export { FreemiumResponsePendingIllustration } from './freemium-response-pending.illustration'
export { FreemiumResponsiveInviteBannerIllustration } from './freemium-responsive-invite-banner.illustration'
export { GiftBoxIllustration } from './gift-box.illustration'
export { GirlAtCellphoneConfirmIdentityIllustration } from './girl-at-cellphone-confirm-identity.illustration'
export { GooglePlayBlackIllustration } from './google-play-black.illustration'
export { GoogleStoreIllustration } from './google-store.illustration'
export { GooglePlayIllustration } from './googleplay.illustration'
export { GreyBlockedLockerIllustration } from './grey-blocked-locker.illustration'
export { HandWithCellphoneIllustration } from './hand-with-cellphone.illustration'
export { HighFiveIllustration } from './high-five.illustration'
export { HomeModalNewPageIllustration } from './home-modal-new-page.illustration'
export { HomePixFailedQRCodeIllustration } from './home-pix-failed-qr-code.illustration'
export { HomePixScanQrCodeIllustration } from './home-pix-scan-qrcode.illustration'
export { HomePixThumbsUp } from './home-pix-thumbs-up.illustration'
export { HomeTransactionDisputeHelpIllustration } from './home-transaction-dispute-help.illustration'
export { HomeTransactionDisputeWarningIllustration } from './home-transaction-dispute-warning.illustration'
export { HouseIllustration } from './house.illustration'
export { IdAssessmentIllustration } from './id-assessment.illustration'
export { IdentityIllustration } from './identity.illustration'
export { IfoodIllustration } from './ifood.illustration'
export { InfoPendingIllustration } from './info-pending.illustration'
export { InsuranceBannerIllustration } from './insurance-banner.illustration'
export { InsuranceCardLifeIllustration } from './insurance-card-life.illustration'
export { InsuranceCardStoreIllustration } from './insurance-card-store.illustration'
export { InsuranceLifeBannerIllustration } from './insurance-life-banner.illustration'
export { InsuranceStatusLoadingIllustration } from './insurance-status-loading.illustration'
export { InsuranceStoreBannerIllustration } from './insurance-store-banner.illustration'
export { InternalServerErrorIllustration } from './internal-server-error.illustration'
export { InvitationSignupMailExpired } from './invitation-signup-mail-expired.illustration'
export { KeyLoadingIllustration } from './key-loading.illustration'
export { KeyRejectedIllustration } from './key-rejected.illustration'
export { KycAnalysisIllustration } from './kyc-analysis.illustration'
export { KycAssessmentRedirectToMobileIllustration } from './kyc-assessment-redirect-to-mobile.illustration'
export { KycConfirmationEmailIllustration } from './kyc-confirmation-email.illustration'
export { KycCreateAccountIllustration } from './kyc-create-account.illustration'
export { KycOnboardingFreemiumIllustration } from './kyc-onboarding-freemium.illustration'
export { KycRejectedIllustration } from './kyc-rejected.illustration'
export { LeagueOfLegendsIllustration } from './leagueoflegends.illustration'
export { LoadingMoreIllustration } from './loading-more.illustration'
export { LockBadgeIllustration } from './lock-badge.illustration'
export { LoginDeviceAuthorizationIllustration } from './login-device-challenge-message.illustration'
export { LoginDeviceSmallMobileAuthorizationIllustration } from './login-device-challenge-message.illustration'
export { LogoLetteringWhiteIllustration } from './logo-lettering-white.illustration'
export { MachineIllustration } from './machine.illustration'
export { MaintenanceIllustration } from './maintenance.illustration'
export { MovingCoinIllustration } from './moving-coin.illustration'
export { NewAccountSwitch } from './new-account-switch.illustration'
export { NextelIllustration } from './nextel.illustration'
export { NotFoundIllustration } from './not-found.illustration'
export { NpsFeedbackIllustration } from './nps-feedback.illustration'
export { NpsFeedbackSentIllustration } from './nps-feedback-sent.illustration'
export { NpsModalIllustration } from './nps-modal.illustration'
export { NpsTokenExpiredIllustration } from './nps-token-expired.illustration'
export { OiIllustration } from './oi.illustration'
export { OiLandlineIllustration } from './oi-landline.illustration'
export { OnboardingCoinsIllustration } from './onboarding-coins.illustration'
export { OnboardingIncomeMoneyIllustration } from './onboarding-income-money.illustration'
export { OnboardingMoneyYieldsIllustration } from './onboarding-money-yields.illustration'
export { OnboardingPiggyIllustration } from './onboarding-piggy.illustration'
export { OnboardingSafeMoneyIllustration } from './onboarding-safe-money.illustration'
export { OpenFinanceIllustration } from './open-finance.illustration'
export { OpenFinanceRedirectIllustration } from './open-finance-redirect.illustration'
export { PaperAirplaneIllustration } from './paper-airplane.illustration'
export { PaperAirplaneLinkIllustration } from './paper-airplane-link.illustration'
export { PaperAirplaneLinkErrorIllustration } from './paper-airplane-link-error.illustration'
export { PaperDocumentsErrorIllustration } from './paper-documents-error.illustration'
export { PapersDocumentIllustration } from './papers-documents.illustration'
export { PaymentLinkErrorCreateModalIllustration } from './payment-link-error-create-modal.illustration'
export { PaymentLinkHomeBannerIllustration } from './payment-link-home-banner.illustration'
export { PaymentLinkSuccessCreateModalIllustration } from './payment-link-success-create-modal.illustration'
export { PaymentLinksBannerIllustration } from './payment-links-banner.illustration'
export { PaymentSuccessIllustration } from './payment-success.illustration'
export { PendingKYCIllustration } from './pending-kyc.illustration'
export { PhonePaperAirplaneIllustration } from './phone-paper-airplane.illustration'
export { PiggyBankIllustration } from './piggy-bank.illustration'
export { PiggyBankNeutralErrorIllustration } from './piggy-bank-neutral-error.illustration'
export { PinBlockedFaceAnalysisIllustration } from './pin-blocked-face-analysis.illustration'
export { PinBlockedLockerIllustration } from './pin-blocked-locker.illustration'
export { PinRegisterIllustration } from './pin-register.illustration'
export { PinRegisterSuccessIllustration } from './pin-register.illustration'
export { PixKeyNoKeysIllustration } from './pix-key-no-keys.illustration'
export { PixKeyRegistrationBannerIllustration } from './pix-key-registration-banner.illustration'
export { PsnStoreIllustration } from './psnstore.illustration'
export { QrCodeSmartphoneIllustration } from './qr-code-smartphone.illustration'
export { RavAutomaticChecked } from './rav-automatic-request-modal.illustration'
export { RavDailyReceivableBannerFollowUpIllustration } from './rav-daily-receivable-followup-banner.illustration'
export { RavDailyReceivableBannerOfferIllustration } from './rav-daily-receivable-offer-banner.illustration'
export { RavDailyReceivableSameDayBannerIlustrationSVG } from './rav-daily-receivable-same-day-banner.illustration'
export { RavDailyReceivableSameDayBannerIllustrationV2SVG } from './rav-daily-receivable-same-day-banner-v2.illustration'
export { RavHistoryEmptyIllustration } from './rav-history-empty-state.illustration'
export { RavSpotPrepaymentError } from './rav-spot-alert-error.illustration'
export { RavSpotRedirectIllustration } from './rav-spot-sidebar-steps.illustration'
export { RavStoneAccountQRCode } from './rav-stone-account-qr-code.illustration'
export { ReceivablesSettlementEveryDayBannerIllustration } from './receivables-settlement-everyday-banner.illustration'
export { RedirectToMobileIllustration } from './redirect-to-mobile.illustration'
export { RefundDialogIllustration } from './refund-dialog.illustration'
export { RegistrationStoreIllustration } from './registration-store.illustration'
export { RequestBobinaSuccessIllustration } from './request-bobina-success.illustration'
export { RequestedLoanStatusIllustration } from './requested-loan-status-steps.illustration'
export { ResetPasswordIllustration } from './reset-password.illustration'
export { ResetPinIllustration } from './reset-pin.illustration'
export { SaleSimulatorBannerIllustration } from './sale-simulator-banner.illustration'
export { ConfirmSaleIllustration } from './sales-confirm-transaction.illustration'
export { SalesHomePixPosTerminalInfoIllustration } from './sales-home-pix-pos-terminal-info.illustration'
export { SaleWithCancellationError } from './sales-with-cancellation-error.illustration'
export { SecurityHomeBannerIllustration } from './security-home-banner.illustration'
export { SecurityOtpLockIllustration } from './security-otp-lock.illustration'
export { SercomtelLandlineIllustration } from './sercomtel-landline.illustration'
export { ServingCustomerIllustration } from './serving-customer.illustration'
export { SettlementCalendarIllustration } from './settlement-calendar.illustration'
export { CoinRefresh } from './shared-coin-refresh.illustration'
export { CoinNegativeError } from './shared-negative-coin-error.illustration'
export { SkyTvIllustration } from './sky-tv.illustration'
export { SpeakerIllustration } from './speaker.illustration'
export { SpinnerIllustration } from './spinner.illustration'
export { SpotifyIllustration } from './spotify.illustration'
export { SteamIllustration } from './steam.illustration'
export { StoreIllustration } from './store.illustration'
export { StoreNeutralErrorIllustration } from './store-neutral-error.illustration'
export { SuccessIllustration } from './success.illustration'
export { SuccessCheckedIllustration } from './success-checked.illustration'
export { SuccessPhoneOnHandIllustration } from './success-phone-on-hand.illustration'
export { TeamListConsultingIllustration } from './team-list-consulting.illustration'
export { TeamListSalesmanDuoIllustration } from './team-list-salesman-duo.illustration'
export { TeamListSittingCustomerIllustration } from './team-list-sitting-customer.illustration'
export { TerminalIllustration } from './terminal.illustration'
export { TerminalSerialNumberIllustration } from './terminal-serial-number.illustration'
export { ThumbsUpIllustration } from './thumbs-up.illustration'
export { TimIllustration } from './tim.illustration'
export { TopupIllustration } from './topup.illustration'
export { TopupOnboardingIllustration } from './topup-onboarding.illustration'
export { TopupPollingIllustration } from './topup-polling.illustration'
export { TopupPresentationIllustration } from './topup-presentation.illustration'
export { TopupSuccessIllustration } from './topup-success.illustration'
export { TransactionalLimitsSunIllustration } from './transactional-limits-sun.illustration'
export { UberIllustration } from './uber.illustration'
export { UnauthorizedIllustration } from './unauthorized.illustration'
export { UpsellBannerIllustration } from './upsell-banner.illustration'
export { UserPfIllustration } from './user-pf.illustration'
export { VerifyEmailClockIllustration } from './verify-email-clock.illustration'
export { VerifyEmailIdentityRecoveryIllustration } from './verify-email-identity-recovery.illustration'
export { VivoIllustration } from './vivo.illustration'
export { VolumeExemptionIllustration } from './volume-exemption-terminals.illustration'
export { VolumeExemptionTwoIllustration } from './volume-exemption-terminals.illustration'
export { WalletNeutralErrorIllustration } from './wallet-neutral-error.illustration'
export { WarningIllustration } from './warning.illustration'
export { WarningDialogIllustration } from './warning-dialog.illustration'
export { WarningNotificationIllustration } from './warning-notification.illustration'
export { WomanOnCalendarIllustration } from './woman-on-calendar.illustration'
export { WomanOnRollerSkaterIllustration } from './woman-on-roller-skater.illustration'
export { XboxGiftCardIllustration } from './xboxgitfcard.illustration'
export { XboxLiveIllustration } from './xboxlive.illustration'
