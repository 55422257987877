import styled from 'styled-components'
const Svg = styled.svg``
const Path = styled.path``

const ArrowSouthEast = () => {
  return (
    <Svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Ilustração de uma seta apontando para baixo"
    >
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L17 15.5858V9C17 8.44772 17.4477 8 18 8C18.5523 8 19 8.44772 19 9V18C19 18.5523 18.5523 19 18 19H9C8.44772 19 8 18.5523 8 18C8 17.4477 8.44772 17 9 17H15.5858L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
      />
    </Svg>
  )
}

export { ArrowSouthEast }
