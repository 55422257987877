/* eslint-disable @nx/enforce-module-boundaries */
import { type SessionDataAccess } from '~/domains/credit/ports/services/check-credit-access'
import { type PathNameOptions } from '~/domains/credit/shared/helpers/initial-path'
import { Bucket } from '~/domains/platform/lib/bucket'
import { type BucketKeyMappings } from '~/domains/platform/lib/bucket/types'

export type CreditBucketKeyMappings = {
  'credit::shared::initial-path': PathNameOptions
  'credit::monitoring::session-key': string
  'credit::monitoring::saga-key': string
  'credit::access::check': SessionDataAccess
  'credit::revolving::balance::view::event::submitted': string
}

class CreditBucket {
  private constructor() {}

  static set<T extends keyof CreditBucketKeyMappings>(key: T, data: BucketKeyMappings[T]) {
    return Bucket.session.set(key, data)
  }

  static get<T extends keyof CreditBucketKeyMappings>(key: T): BucketKeyMappings[T] | undefined {
    return Bucket.session.get(key)
  }

  static remove(key: keyof CreditBucketKeyMappings) {
    Bucket.session.remove(key)
  }
}

export { CreditBucket }
