export function EmbratelIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="32" height="32" fill="white" />
      <g clipPath="url(#clip0_2706_9195)">
        <path
          d="M14.4777 12.6278C14.6587 11.7522 14.8276 10.9232 14.8448 10.8404H19.8225C20.0913 10.8404 20.2172 10.756 20.2603 10.7198H20.2809L20.2878 10.6888L19.8173 10.5543L20.3395 8H11.4683L10.1722 14.2824C11.3529 13.7533 12.7834 13.1983 14.4777 12.6278Z"
          fill="#004388"
        />
        <path
          d="M13.4608 17.5486H17.4939L18.0679 14.7788H14.0347C14.0347 14.7788 14.1519 14.21 14.2812 13.5757C13.3849 13.9205 12.7196 14.1807 12.0664 14.4582C11.4132 14.7357 10.5255 15.1339 9.92572 15.4527L8.02808 24.66H17.6163L18.2833 21.4904H12.6472C12.7024 21.2267 13.4246 17.7244 13.4608 17.5486Z"
          fill="#004388"
        />
        <path
          d="M14.8 11.9125C3.89151 15.451 3.16761 18.4621 8.7175 18.6396C8.64166 18.6258 8.56927 18.6137 8.49689 18.6017C8.49689 18.6017 8.49861 18.6017 8.50033 18.6017C6.89224 18.45 6.25625 18.088 6.18386 17.4572C5.99082 15.7716 10.4273 13.3603 21.677 10.2441C24.2727 9.52536 27.1528 8.94624 30.1794 8.34127C30.1725 8.34127 30.1656 8.343 30.1587 8.34472C30.2052 8.33438 30.2535 8.32404 30.3017 8.31542C30.3483 8.3068 30.3948 8.29646 30.4431 8.28784C25.5861 9.05655 19.8862 10.2682 14.8034 11.9125H14.8Z"
          fill="#75B94E"
        />
        <path
          d="M15.548 12.4365C20.3258 10.7301 25.6257 9.27888 30.1777 8.34126C27.1511 8.94624 24.271 9.52535 21.6753 10.2441C10.4256 13.3603 5.70646 15.8905 6.046 17.4589C6.18044 18.0794 6.89055 18.4517 8.50036 18.6034C7.11289 18.3621 6.61305 18.2035 6.59237 17.5675C6.55962 16.4765 9.42591 14.6306 15.548 12.4365Z"
          fill="#FFD618"
        />
      </g>
      <defs>
        <clipPath id="clip0_2706_9195">
          <rect width="25.1227" height="16.66" fill="white" transform="translate(5.31863 8)" />
        </clipPath>
      </defs>
    </svg>
  )
}
