import { loanInstallmentsMock } from '~/domains/credit/loans/loan-details/components/loan-details.mock'
import { contractsSuccess } from '~/scaffold/builders/credit/revolving-credit/contract'
import { type ScenarioMock } from '~/scaffold/mocks/types/mock-flags-types'

export const loanRenegociationGraceDetails: ScenarioMock[] = [
  {
    scenario: 'Renegociação ativa com carência',
    mocks: [
      {
        method: 'GET',
        url: '**/experience-bff-service/api/legacy/web/v1/loans/**/summary',
        status: 200,
        delay: 0,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({
          current_summary: {
            tax_amount: 445694.158,
            interest_economy_amount: 0.0,
            paid_amount: 0.0,
            number_of_installments: 6,
            installment_plan_id: 'jr6y1buqerjkb2vhbwx3ut4tq',
            installment_plan_origin_type: 'WorkingCapitalConcession',
            label: 'Empréstimo',
            initial_amount: 38322749.46606418,
            initial_installment_amount: 5474678.49515203,
            future_balance: 38787376.73144871,
            current_balance: 38787376.73144871,
            present_balance: 37400298.82399842,
            credit_amount: 35000000.0,
            number_of_settled_installments: 0,
            number_of_overdue_installments: 2,
            due_date: '2025-03-24',
            is_retention_enabled: false,
            retention_rate: 0.16,
            monthly_interest_rate: 0.0199,
            yearly_interest_rate: 0.2668,
            yearly_total_effective_cost_rate: 0.3169,
            contract_uri:
              'https://credit-sandbox.stone.com.br/contracts-service/api/v1/contracts/jr6xzaxvcp3r6f7c691jxda95',
            issuer: {
              name: 'Stone Sociedade de Credito Direto S.A.',
              document_number: '34590184000109',
              document_type: 'cnpj'
            },
            holder: {
              name: 'Stone Sociedade de Credito Direto S.A.',
              document_number: '34590184000109',
              document_type: 'cnpj'
            },
            grace_period_in_days: 30,
            plans: [
              {
                amount_by_installment: {
                  value: 1020.33,
                  description: 'R$ 1020,33',
                  type: 'money'
                },
                installments: {
                  value: 2,
                  description: '2x',
                  type: 'integer'
                },
                total: {
                  value: 2040.67,
                  description: 'R$ 2040,67',
                  type: 'money'
                },
                type: 'GraceWithInterest'
              },
              {
                amount_by_installment: {
                  value: 502.33,
                  description: 'R$ 502,33',
                  type: 'money'
                },
                installments: {
                  value: 4,
                  description: '4x',
                  type: 'integer'
                },
                total: {
                  value: 2040.67,
                  description: 'R$ 2040,67',
                  type: 'money'
                },
                type: 'Regular'
              }
            ],
            current_installment: {
              id: 'jr6y1buw8z3f1f3b9qrwwub1t',
              installment_number: 1,
              start_date: '2024-08-17',
              due_date: '2024-09-23',
              days_until_due_date: 0,
              is_due_soon: false,
              days_overdue: 37,
              is_collection_enabled: true,
              is_overdue: true,
              status: 'Open',
              paid_amount: 0.0,
              initial_amount: 5474678.49515203,
              future_balance: 5786370.33557338,
              present_balance: 5786370.33557338,
              interest_economy_amount: 0.0,
              penalty_fee_amount: 109493.56990304,
              penalty_interest_amount: 67521.02802144,
              late_payment_interest_amount: 134677.24249688,
              total_interest_and_fee_sum_amount: 311691.84042136,
              total_debt_amount: 5786370.34
            },
            next_installment: {
              installment_number: 2,
              start_date: '2024-09-23',
              due_date: '2024-10-22',
              status: 'open',
              future_balance: 5627613.9201152,
              present_balance: 5627613.9201152
            }
          },
          renegotiation_summary: {
            status: 'Active',
            status_description: 'Renegociação ativa',
            loan_renegotiation_contract_id: 'jqqu3e377j4u9q7f77ufbr7yw',
            contract_signature_date: '2024-11-11',
            creation_date: '2024-11-11'
          },
          previousSummary: {
            initialInstallmentAmount: 313.8764316497,
            futureBalance: 941.6292949491,
            taxAmount: 6.5610148428,
            numberOfInstallments: 4,
            retentionRate: 0.1,
            monthlyInterestRate: 0.0399,
            yearlyInterestRate: 0.5992,
            yearlyTotalEffectiveCostRate: 0.6617,
            dueDate: '2023-02-28T00:00:00Z'
          }
        })
      },
      {
        method: 'GET',
        url: '**/experience-bff-service/api/legacy/web/v1/installment-plans/**/installments',
        status: 200,
        delay: 0,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(loanInstallmentsMock)
      },
      {
        method: 'GET',
        url: '**/contracts-service/api/v1/contracts**',
        status: 200,
        delay: 0,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(contractsSuccess)
      },
      {
        method: 'GET',
        url: '**/experience-bff-service/api/legacy/web/v1/loans**',
        status: 200,
        delay: 0,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({
          items: [
            {
              id: 'jqqu3e377j4u9q7f77ufbr7yw',
              document: '02435144000104',
              type: 'Installments',
              creation_date: '2024-04-03T13:56:38Z',
              last_update_date: '2024-04-03T14:28:23Z',
              status: 'Active',
              concession_proposal_id: 'jr61xkx8cf55euma1e9b9c3yt',
              disbursement_date: '2024-04-03T14:00:51Z',
              repayment_method: 'PaymentService',
              contract: {
                due_date: '2024-10-15',
                credit_amount: 20000.0,
                interest_rate: 0.02,
                annual_interest_rate: 0.27,
                contract_number: '4956',
                contract_uri:
                  'https://credit-sandbox.stone.com.br/contracts-service/api/v1/contracts/jr3mwppk78rm8r6r9t5n9a3an',
                acceptance_date: '2024-04-03T13:56:23Z',
                annual_total_effective_cost_rate: 0.32,
                operation_fee_amount: 0.0,
                tax_amount: 228.64,
                borrower: {
                  document: '02435144000104',
                  name: 'GUEDES ROGERIO AIRES LEAL',
                  fantasy_name: 'GUEDES ROGERIO AIRES LEAL',
                  email: 'credito.stone+3+mlp@gmail.com',
                  address: {
                    street_name: 'Rua Oliveira Fonseca',
                    number: '5142',
                    complement: '123',
                    neighborhood: 'Campo Grande',
                    city: 'Recife',
                    state: 'PE',
                    postal_code: '52040250'
                  }
                },
                payment_term_in_months: 6,
                disbursement: {
                  owner: {
                    name: 'Nome do CPF 39117514860',
                    document: '39117514860'
                  },
                  bank_number: '197',
                  bank_name: 'Stone Pagamentos S.A.',
                  branch_number: '1',
                  branch_digit: '',
                  number: '145854',
                  digit: ''
                },
                warranty: {
                  owner: {
                    name: 'Nome do CPF 39117514860',
                    document: '39117514860'
                  },
                  bank_number: '197',
                  bank_name: 'Stone Pagamentos S.A.',
                  branch_number: '1',
                  branch_digit: '',
                  number: '218354',
                  digit: ''
                }
              }
            }
          ],
          paging: {
            total_items: 38,
            current_page: 1,
            page_size: 1,
            page_count: 38
          }
        })
      }
    ]
  }
]
