import { analiticaInstance } from 'shared/analitica'

export default analiticaInstance.eventFactory('Miscellaneous', {
  profileMenu: {
    /**
     * @analitica When the user click on go back button
     */
    click: (item: string) => ({
      label: 'home web - menu perfil chosen button',
      metadata: { 'menu-perfil': item }
    })
  },
  delayPayments: {
    click: () => ({
      label: 'SmartFees-Profile Clicked',
      metadata: {
        'Property Type': 'Event',
        'Property Name': 'Event'
      }
    })
  }
})
