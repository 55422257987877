import axios from 'axios'
import { SALES_SECTION_API_ENDPOINT } from 'shared/envs'

// eslint-disable-next-line @nx/enforce-module-boundaries
import { isomorphicCookies } from '~/lib/helpers/utils/cookies'

const baseURL = SALES_SECTION_API_ENDPOINT()

export const httpClientSales = axios.create({
  baseURL
})

httpClientSales.interceptors.request.use(request => {
  const token = isomorphicCookies.getAll()?.token || ''
  if (token) {
    request.headers.Authorization = `Bearer ${token}`
  }
  return request
})
