export function SaleWithCancellationError(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="106" height="104" viewBox="0 0 106 104" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_9462_18153)">
        <path
          d="M54.5804 23.0937C54.5804 24.4955 54.4505 25.8696 54.2083 27.1945C54.1197 27.7064 54.0055 28.2104 53.8815 28.7124C53.722 29.3561 53.5271 29.992 53.3086 30.6181C53.1492 31.0866 52.97 31.5473 52.7692 32.006C52.6609 32.2856 52.5329 32.5651 52.401 32.8427C52.1924 33.2797 51.9778 33.7129 51.7356 34.142C51.5919 34.4039 51.4502 34.6598 51.2907 34.9118C47.2785 41.5896 39.9688 46.0565 31.6274 46.0546L23.3156 46.0526L23.2329 19.8119L23.1699 2.69419L25.2469 2.69222L40.961 2.08389C41.2937 2.23351 41.6205 2.38904 41.9493 2.55441C42.5143 2.83593 43.0675 3.14108 43.5971 3.47182C38.2777 3.72775 32.9583 3.98762 27.6369 4.24748C26.9793 4.27898 26.9912 5.30467 27.6526 5.27514C33.4406 4.98968 39.2384 4.70816 45.0303 4.4227C45.0874 4.46601 45.1484 4.50932 45.2055 4.55263C49.7295 7.86397 52.9798 12.7995 54.1315 18.5028C54.4249 19.9812 54.5824 21.5188 54.5804 23.0937Z"
          fill="#1D232A"
        />
        <path
          d="M44.8037 31.1575C40.3249 43.0228 27.0894 49.0273 15.2399 44.5721C12.1392 43.4086 9.44404 41.6427 7.22926 39.4594C5.24285 37.4986 3.64821 35.2051 2.50047 32.7048L7.96949 28.5608C7.68403 27.7575 7.46354 26.9326 7.30604 26.09L1.59881 30.4153C1.43737 29.9349 1.29169 29.4506 1.16373 28.9624C1.18932 28.9506 1.21294 28.9348 1.23854 28.9171L6.94774 24.5899L7.02452 23.3083C7.02452 23.3083 6.57369 23.6449 6.35713 23.8083L0.913703 27.9308C0.665648 26.7811 0.508153 25.6097 0.441217 24.4265C0.266004 21.3554 0.710928 18.1956 1.8508 15.1264C1.86655 15.091 1.87639 15.0555 1.89214 15.0201C5.13654 6.42678 12.9759 0.90657 21.5554 0.207685C22.0889 0.164374 22.6264 0.138781 23.1658 0.134843L31.58 0.125H31.6902C33.6294 0.126969 35.5154 0.367149 37.3108 0.821916C38.1279 1.0306 38.9311 1.28062 39.7067 1.57002C40.128 1.72948 40.5474 1.90273 40.9608 2.08385L33.2849 2.38112C34.1964 2.8162 35.0665 3.3064 35.8934 3.84385C38.4606 3.71983 41.0297 3.5958 43.5969 3.47177C43.6048 3.47177 43.6087 3.47768 43.6146 3.47965C44.1028 3.77692 44.5753 4.08994 45.0301 4.42265C42.4393 4.55061 39.8465 4.67661 37.2538 4.80261C39.8426 6.76539 41.9432 9.24003 43.463 12.0198C46.5676 17.6857 47.2685 24.6293 44.8037 31.1575Z"
          fill="#BBBDBF"
        />
        <path
          d="M12.3225 34.2868L12.3233 34.2876C12.4805 34.4435 12.6415 34.5962 12.8057 34.7433L37.4193 16.1061C37.3203 15.907 37.2172 15.7093 37.1106 15.5142C36.0388 13.559 34.5505 11.827 32.7113 10.4639C32.2466 10.1201 31.7606 9.80005 31.2531 9.50885L8.14529 27.0095C8.28905 27.573 8.46702 28.1274 8.67191 28.6716L12.3225 34.2868ZM12.3225 34.2868C10.7039 32.6912 9.46518 30.7709 8.67193 28.6716L12.3225 34.2868Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
          strokeWidth="0.787476"
        />
        <path
          d="M2.26179 31.1102C2.19359 30.9265 2.12725 30.7432 2.06275 30.5588L7.04736 26.7826C7.17218 27.3361 7.32444 27.8829 7.50478 28.4195L2.65917 32.0912C2.51872 31.7668 2.38567 31.4396 2.26179 31.1102Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
          strokeWidth="0.787476"
        />
        <mask id="path-5-inside-1_9462_18153" fill="white">
          <path d="M1.59962 30.4173C1.60356 30.431 1.6075 30.4429 1.61144 30.4566C1.60553 30.4429 1.60159 30.431 1.59766 30.4173H1.59962Z" />
        </mask>
        <path
          d="M1.59962 30.4173C1.60356 30.431 1.6075 30.4429 1.61144 30.4566C1.60553 30.4429 1.60159 30.431 1.59766 30.4173H1.59962Z"
          fill="#FCFCFC"
        />
        <path
          d="M1.59962 30.4173L2.35679 30.2009L2.19359 29.6298H1.59962V30.4173ZM1.61144 30.4566L0.887654 30.7669L2.3686 30.2403L1.61144 30.4566ZM1.59766 30.4173V29.6298H0.55362L0.84049 30.6336L1.59766 30.4173ZM0.842459 30.6336C0.846935 30.6493 0.851185 30.663 0.852884 30.6686C0.855122 30.6758 0.85481 30.6749 0.854271 30.673L2.3686 30.2403C2.36413 30.2246 2.35988 30.2109 2.35818 30.2053C2.35594 30.1981 2.35625 30.199 2.35679 30.2009L0.842459 30.6336ZM2.33522 30.1464C2.33969 30.1568 2.34402 30.168 2.34796 30.1793C2.35172 30.1902 2.35406 30.1982 2.35482 30.2009L0.84049 30.6336C0.850979 30.6703 0.865405 30.715 0.887654 30.7669L2.33522 30.1464ZM1.59766 31.2047H1.59962V29.6298H1.59766V31.2047Z"
          fill="#FCFCFC"
          mask="url(#path-5-inside-1_9462_18153)"
        />
        <path
          d="M22.3587 33.8113C21.5004 33.8113 20.6125 33.6558 19.7384 33.3427C16.4861 32.1753 14.7734 28.7458 14.9958 26.1078C15.0096 25.9444 15.1513 25.8282 15.3147 25.8381C15.4781 25.8519 15.5982 25.9956 15.5845 26.157C15.3797 28.5746 16.9527 31.7146 19.9372 32.7876C22.087 33.5593 24.3077 33.2896 25.8768 32.069C27.0619 31.1457 27.6624 29.7991 27.5246 28.3718C27.2627 25.6806 25.6051 24.4994 23.5045 23.0071L23.3116 22.8713C21.1657 21.3416 19.3821 19.8848 19.3013 17.5184C19.2482 15.9651 19.951 14.5398 21.2287 13.6086C22.7426 12.5042 24.8452 12.2758 26.8513 12.9963C29.2412 13.8547 30.5426 15.9769 30.9619 17.6779C31.0013 17.8354 30.9048 17.9968 30.7453 18.0362C30.5859 18.0756 30.4264 17.9791 30.387 17.8196C30.0031 16.2683 28.8199 14.3331 26.6485 13.5535C24.7999 12.89 22.9493 13.0849 21.5732 14.087C20.457 14.902 19.8427 16.1462 19.888 17.4987C19.9608 19.5993 21.5634 20.9046 23.6521 22.3909L23.8431 22.5268C25.9791 24.0446 27.8218 25.3558 28.1093 28.3167C28.2687 29.9487 27.5856 31.4882 26.237 32.5375C25.1562 33.3762 23.7978 33.8113 22.3587 33.8113Z"
          fill="#1D232A"
        />
        <path
          d="M18.8848 35.6205C18.8513 35.6205 18.8179 35.6146 18.7844 35.6028C18.6308 35.5477 18.5501 35.3784 18.6052 35.2248L27.3167 10.8662C27.3718 10.7127 27.5411 10.632 27.6947 10.6871C27.8482 10.7422 27.929 10.9115 27.8738 11.0651L19.1624 35.4256C19.1191 35.5457 19.0068 35.6205 18.8848 35.6205Z"
          fill="#1D232A"
        />
        <path
          d="M1.35246 28.0927L6.58104 24.1329L6.5659 24.3855L1.40088 28.3002C1.3843 28.2311 1.36815 28.1619 1.35246 28.0927Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
          strokeWidth="0.787476"
        />
        <path
          d="M12.0829 35.2011C12.01 35.2011 11.9372 35.1736 11.8801 35.1204C10.0098 33.3427 8.56285 31.1082 7.69268 28.6592C6.58234 25.5329 6.43075 22.1841 7.25957 18.9732C7.40329 18.4121 7.58834 17.8294 7.80687 17.2427C8.49 15.4355 9.46254 13.7857 10.6949 12.3407C10.8012 12.2166 10.9863 12.2009 11.1103 12.3072C11.2344 12.4135 11.2501 12.5986 11.1438 12.7226C9.95668 14.1164 9.01958 15.7071 8.36007 17.4494C8.14745 18.0164 7.97027 18.5775 7.83246 19.1189C7.03317 22.2156 7.17885 25.4443 8.24982 28.4584C9.08849 30.8188 10.4843 32.9745 12.2876 34.6893C12.4057 34.8015 12.4097 34.9885 12.2975 35.1066C12.2384 35.1696 12.1616 35.2011 12.0829 35.2011Z"
          fill="#1D232A"
        />
        <path
          d="M69.9484 31.8071V87.9639H4.59774V73.6929H0.140625V60.0204H4.60365V46.0545H46.6194V31.8071H69.9484Z"
          fill="#BBBDBF"
        />
        <path
          d="M74.8801 87.5702V32.2008H80.4869V87.5702H74.8801Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
          strokeWidth="0.787476"
        />
        <path
          d="M70.343 87.5702V32.2008H71.9041V87.5702H70.343Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
          strokeWidth="0.787476"
        />
        <path
          d="M85.7022 45.4796V60.0242H90.1691V73.6988H85.7022V87.9639H80.8809V31.807H90.1691V45.4796H85.7022Z"
          fill="#BBBDBF"
        />
        <path d="M74.4861 31.8071H72.2988V87.9639H74.4861V31.8071Z" fill="#BBBDBF" />
        <path d="M46.6192 59.4436H44.3867V74.0749H46.6192V59.4436Z" fill="#1D232A" />
        <path
          d="M36.5078 87.964H44.3885V46.0545H42.2721L40.4905 59.0085L40.2798 60.5362L38.4982 73.4862L38.2875 75.0139L36.5078 87.964Z"
          fill="#1D232A"
        />
        <path
          d="M85.7019 59.4318V60.0224H4.60367C4.59777 60.0224 4.58989 60.0224 4.58398 60.0205H4.60367V59.4318H85.7019Z"
          fill="#1D232A"
        />
        <path d="M85.7016 45.4639H46.6191V46.0545H85.7016V45.4639Z" fill="#1D232A" />
        <path d="M85.7018 73.6969H4.59766V74.2875H85.7018V73.6969Z" fill="#1D232A" />
      </g>
      <g filter="url(#filter0_d_9462_18153)">
        <path
          d="M83.8091 103.875C95.9866 103.875 105.858 94.0032 105.858 81.8257C105.858 69.6482 95.9866 59.7763 83.8091 59.7763C71.6316 59.7763 61.7598 69.6482 61.7598 81.8257C61.7598 94.0032 71.6316 103.875 83.8091 103.875Z"
          fill="#4A4F55"
        />
      </g>
      <path
        d="M94.3891 89.1316C95.2926 90.037 95.2926 91.5015 94.3891 92.405C93.9373 92.8568 93.3448 93.0827 92.7523 93.0827C92.1599 93.0827 91.5674 92.8568 91.1156 92.405L83.8097 85.0991L76.5019 92.405C76.0501 92.8568 75.4576 93.0827 74.8651 93.0827C74.2727 93.0827 73.6802 92.8568 73.2284 92.405C72.3249 91.5015 72.3249 90.037 73.2284 89.1316L80.5362 81.8257L73.2284 74.5179C72.3249 73.6143 72.3249 72.148 73.2284 71.2444C74.132 70.3409 75.5965 70.3409 76.5019 71.2444L83.8097 78.5522L91.1156 71.2444C92.0192 70.3409 93.4855 70.3409 94.3891 71.2444C95.2926 72.148 95.2926 73.6143 94.3891 74.5179L87.0831 81.8257L94.3891 89.1316Z"
        fill="#FCFCFC"
      />
      <defs>
        <filter
          id="filter0_d_9462_18153"
          x="59.3973"
          y="58.9889"
          width="48.8245"
          height="48.8235"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.57495" />
          <feGaussianBlur stdDeviation="1.18121" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.290196 0 0 0 0 0.309804 0 0 0 0 0.333333 0 0 0 0.32 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9462_18153" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9462_18153" result="shape" />
        </filter>
        <clipPath id="clip0_9462_18153">
          <rect width="90.0283" height="87.839" fill="white" transform="translate(0.140625 0.125)" />
        </clipPath>
      </defs>
    </svg>
  )
}
