export function CalendarCheckedIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="153" height="128" viewBox="0 0 153 128" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M98.6175 10.0726C98.58 9.51508 98.4975 8.97008 98.375 8.44008C97.3 3.76508 93.1025 0.267578 88.1075 0.267578C82.2975 0.267578 77.5724 4.99258 77.5724 10.8026C77.5724 16.6101 82.2975 21.3351 88.1075 21.3351C91.46 21.3351 94.4525 19.7601 96.385 17.3126C97.7975 15.5176 98.6425 13.2551 98.6425 10.8026C98.6425 10.5576 98.635 10.3126 98.6175 10.0726ZM88.1075 18.8351C83.6775 18.8351 80.0725 15.2326 80.0725 10.8026C80.0725 6.37258 83.6775 2.76758 88.1075 2.76758C92.0325 2.76758 95.31 5.59758 96.005 9.32258C96.095 9.80258 96.1425 10.2976 96.1425 10.8026C96.1425 12.7476 95.45 14.5301 94.2975 15.9226C92.82 17.7001 90.5925 18.8351 88.1075 18.8351Z"
        fill="#42EC9A"
      />
      <path
        d="M98.6425 10.8025C98.6425 13.255 97.7975 15.5175 96.385 17.3125H94.2975V15.9225C95.45 14.53 96.1425 12.7475 96.1425 10.8025L98.6175 10.0725C98.635 10.3125 98.6425 10.5575 98.6425 10.8025Z"
        fill="#1D232A"
      />
      <path
        d="M60.85 10.0726C60.8125 9.51508 60.73 8.97008 60.6075 8.44008C59.5325 3.76508 55.335 0.267578 50.34 0.267578C44.5299 0.267578 39.8049 4.99258 39.8049 10.8026C39.8049 16.6101 44.5299 21.3351 50.34 21.3351C53.6925 21.3351 56.685 19.7601 58.6175 17.3126C60.03 15.5176 60.875 13.2551 60.875 10.8026C60.875 10.5576 60.8675 10.3126 60.85 10.0726ZM50.34 18.8351C45.91 18.8351 42.3049 15.2326 42.3049 10.8026C42.3049 6.37258 45.91 2.76758 50.34 2.76758C54.265 2.76758 57.5425 5.59758 58.2375 9.32258C58.3275 9.80258 58.375 10.2976 58.375 10.8026C58.375 12.7476 57.6825 14.5301 56.53 15.9226C55.0525 17.7001 52.825 18.8351 50.34 18.8351Z"
        fill="#42EC9A"
      />
      <path
        d="M60.875 10.8025C60.875 13.255 60.03 15.5175 58.6175 17.3125H56.53V15.9225C57.6825 14.53 58.375 12.7475 58.375 10.8025L60.85 10.0725C60.8675 10.3125 60.875 10.5575 60.875 10.8025Z"
        fill="#1D232A"
      />
      <path d="M97.3825 95.5H0.25L24.4175 12.8275H121.55L97.3825 95.5Z" fill="#E8E9EA" />
      <path
        d="M31.4 94.0875C31.365 94.0875 31.33 94.0825 31.295 94.0725C31.0975 94.015 30.9825 93.805 31.04 93.6075L53.17 17.9076C53.2275 17.7076 53.4375 17.5951 53.635 17.6526C53.8325 17.7101 53.9475 17.9201 53.89 18.1176L31.76 93.8175C31.7125 93.9825 31.5625 94.0875 31.4 94.0875Z"
        fill="#1D232A"
      />
      <path
        d="M63.7776 94.0875C63.7426 94.0875 63.7076 94.0825 63.6726 94.0725C63.4751 94.015 63.3601 93.805 63.4176 93.6075L85.6776 17.4625C85.7351 17.2625 85.9451 17.15 86.1426 17.2075C86.3401 17.265 86.4551 17.475 86.3976 17.6725L64.1376 93.8175C64.0901 93.9825 63.9401 94.0875 63.7776 94.0875Z"
        fill="#1D232A"
      />
      <path d="M121.55 12.8275L117.305 27.3451H20.1724L24.4174 12.8275H121.55Z" fill="#42EC9A" />
      <path d="M121.588 12.8275L97.3825 95.5H145.755L121.588 12.8275Z" fill="#00A868" />
      <path d="M121.588 12.8275L97.3825 95.5H112.803L122.43 15.7075L121.588 12.8275Z" fill="#1D232A" />
      <path d="M121.55 12.8275L117.305 27.3451H20.1724L24.4174 12.8275H121.55Z" fill="#00A868" />
      <path
        d="M104.025 73.1575H8.39008C8.18258 73.1575 8.01508 72.99 8.01508 72.7825C8.01508 72.575 8.18258 72.4075 8.39008 72.4075H104.025C104.233 72.4075 104.4 72.575 104.4 72.7825C104.4 72.99 104.23 73.1575 104.025 73.1575Z"
        fill="#1D232A"
      />
      <path
        d="M110.665 50.4375H15.0325C14.825 50.4375 14.6575 50.27 14.6575 50.0625C14.6575 49.855 14.825 49.6875 15.0325 49.6875H110.665C110.873 49.6875 111.04 49.855 111.04 50.0625C111.04 50.27 110.873 50.4375 110.665 50.4375Z"
        fill="#1D232A"
      />
      <path
        d="M48.9849 24.73C47.9449 24.73 47.3449 23.8975 47.6449 22.87L48.2999 20.63C48.5999 19.6025 49.6874 18.77 50.7274 18.77C51.7674 18.77 52.3674 19.6025 52.0674 20.63L51.4124 22.87C51.1099 23.895 50.0249 24.73 48.9849 24.73Z"
        fill="#1D232A"
      />
      <path
        d="M48.985 25.105C48.3725 25.105 47.8525 24.865 47.5275 24.43C47.195 23.9875 47.1075 23.38 47.2875 22.765L47.9425 20.525C48.2925 19.33 49.515 18.395 50.73 18.395C51.3425 18.395 51.8625 18.635 52.1875 19.07C52.52 19.5125 52.6075 20.12 52.4275 20.735L51.7725 22.975C51.4225 24.1675 50.1975 25.105 48.985 25.105ZM50.7275 19.14C49.86 19.14 48.9125 19.87 48.66 20.73L48.005 22.97C47.89 23.3625 47.9325 23.7175 48.1275 23.975C48.31 24.2175 48.615 24.35 48.985 24.35C49.8525 24.35 50.8 23.62 51.0525 22.76L51.7075 20.52C51.8225 20.1275 51.78 19.7725 51.585 19.515C51.4025 19.275 51.0975 19.14 50.7275 19.14Z"
        fill="#1D232A"
      />
      <path
        d="M50.34 21.3175C44.5325 21.3175 39.8049 16.5925 39.8049 10.7825C39.8049 4.975 44.53 0.25 50.34 0.25C51.03 0.25 51.59 0.81 51.59 1.5C51.59 2.19 51.03 2.75 50.34 2.75C45.91 2.75 42.305 6.355 42.305 10.785C42.305 15.215 45.91 18.82 50.34 18.82C51.03 18.82 51.59 19.38 51.59 20.07C51.59 20.76 51.03 21.3175 50.34 21.3175Z"
        fill="#42EC9A"
      />
      <path
        d="M86.7526 24.7299C85.7126 24.7299 85.1126 23.8974 85.4126 22.8699L86.0676 20.6299C86.3676 19.6024 87.4551 18.7699 88.4951 18.7699C89.5351 18.7699 90.135 19.6024 89.8351 20.6299L89.1801 22.8699C88.8801 23.8949 87.7926 24.7299 86.7526 24.7299Z"
        fill="#1D232A"
      />
      <path
        d="M86.7525 25.105C86.14 25.105 85.62 24.865 85.295 24.43C84.9625 23.9875 84.875 23.38 85.055 22.765L85.71 20.5225C86.06 19.3275 87.2825 18.3925 88.4975 18.3925C89.11 18.3925 89.63 18.6325 89.955 19.0675C90.2875 19.51 90.3749 20.1175 90.195 20.7325L89.54 22.975C89.19 24.1675 87.9675 25.105 86.7525 25.105ZM88.495 19.14C87.6275 19.14 86.68 19.87 86.4275 20.73L85.7725 22.9725C85.6575 23.3625 85.7 23.72 85.895 23.9775C86.0775 24.22 86.3825 24.3525 86.7525 24.3525C87.62 24.3525 88.5675 23.6225 88.82 22.7625L89.475 20.52C89.59 20.13 89.5475 19.7725 89.3525 19.515C89.1725 19.275 88.8675 19.14 88.495 19.14Z"
        fill="#1D232A"
      />
      <path
        d="M88.1075 21.3175C82.3 21.3175 77.5725 16.5925 77.5725 10.7825C77.5725 4.975 82.3 0.25 88.1075 0.25C88.7975 0.25 89.3575 0.81 89.3575 1.5C89.3575 2.19 88.7975 2.75 88.1075 2.75C83.6775 2.75 80.0725 6.355 80.0725 10.785C80.0725 15.215 83.6775 18.82 88.1075 18.82C88.7975 18.82 89.3575 19.38 89.3575 20.07C89.3575 20.76 88.7975 21.3175 88.1075 21.3175Z"
        fill="#42EC9A"
      />
      <path
        d="M112.785 95.4675C112.768 95.4675 112.748 95.4675 112.73 95.4625C112.525 95.4325 112.385 95.24 112.415 95.035L122.72 26.3175C122.75 26.1175 122.933 25.975 123.135 26C123.335 26.025 123.483 26.2025 123.465 26.405L118.313 84.65C118.295 84.8575 118.11 85.01 117.905 84.99C117.698 84.9725 117.545 84.79 117.565 84.5825L121.628 38.6575L113.155 95.1425C113.128 95.3325 112.968 95.4675 112.785 95.4675Z"
        fill="#1D232A"
      />
      <g filter="url(#filter0_d_2502_5690)">
        <path
          d="M122 123C137.464 123 150 110.464 150 95C150 79.536 137.464 67 122 67C106.536 67 94 79.536 94 95C94 110.464 106.536 123 122 123Z"
          fill="#E8E9EA"
        />
      </g>
      <path
        d="M117.352 109.128C116.639 109.128 115.922 108.869 115.358 108.347L106.029 99.7258C104.837 98.6231 104.764 96.7656 105.864 95.5736C106.967 94.3816 108.824 94.3087 110.016 95.409L117.187 102.037L133.817 84.0412C134.92 82.8491 136.777 82.7762 137.969 83.8766C139.161 84.9793 139.234 86.8367 138.134 88.0287L119.51 108.183C118.932 108.811 118.144 109.128 117.352 109.128Z"
        fill="#00A868"
      />
      <defs>
        <filter
          id="filter0_d_2502_5690"
          x="91"
          y="66"
          width="62"
          height="62"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.290196 0 0 0 0 0.309804 0 0 0 0 0.333333 0 0 0 0.32 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2502_5690" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2502_5690" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
