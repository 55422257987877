import { Endpoints } from '../endpoints'
import { httpClient } from '../http-client'

import { type SwhResponse } from './types'

export namespace Categories {
  export type Subcategory = {
    id: string
    name: string
  }

  export type Item = {
    id: string
    name: string
    subcategories?: Subcategory[]
  }

  export const get = async (id: string): Promise<Item[]> => {
    try {
      const response = await httpClient.get<SwhResponse<Item>>(Endpoints.categories(id))
      return response.data.items
    } catch (e) {
      console.error(e)
      return []
    }
  }

  export const getSubcategoriesByCategoryId = async (storeId: string, categoryId: string): Promise<Item[]> => {
    const response = await httpClient.get<SwhResponse<Item>>(
      Endpoints.subcategories.getByCategoryId(storeId, categoryId)
    )
    return response.data.items
  }
}
