import { type FlagProps } from '~/scaffold/mocks/types/mock-flags-types'

import { loanRenegociationGraceCard } from './loan-renegociation-grace-card.mock'
import { loanRenegociationGraceDetails } from './loan-renegociation-grace-details.mock'

const creditHomeFlags: FlagProps[] = [
  {
    name: 'loan-renegociation-grace-card',
    groupName: 'Alavanca de Carência',
    scenarios: [
      'Renegociação ativa com carência',
      'Renegociação ativa sem carência',
      'Renegociação em processo com carência',
      'Renegociação em processo sem carência'
    ],
    initialScenario: 'Renegociação ativa com carência',
    handlers: loanRenegociationGraceCard
  },
  {
    name: 'loan-renegociation-grace-details',
    groupName: 'Alavanca de Carência',
    scenarios: ['Renegociação ativa com carência'],
    initialScenario: 'Renegociação ativa com carência',
    handlers: loanRenegociationGraceDetails
  }
]

export { creditHomeFlags }
