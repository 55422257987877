import { type ScenarioMock } from '~/scaffold/mocks/types/mock-flags-types'

export const loanRenegociationGraceCard: ScenarioMock[] = [
  {
    scenario: 'Renegociação em processo com carência',
    mocks: [
      {
        method: 'GET',
        url: '**/experience-bff-service/api/web/v1/home**',
        status: 200,
        delay: 0,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({
          content: {
            sections: [
              {
                id: 'loans',
                title: 'Home.Sections.Loans.Title',
                elements: [
                  {
                    id: 'jqqu3e377j4u9q7f77ufbr7yw',
                    type: 'current_loan',
                    status: 'active',
                    status_description: 'Ativo',
                    credit_amount: 2022864,
                    total_of_installment: 10,
                    initial_installment_amount: 225081,
                    installment_plan_id: 'jr3kfct7fhqp4bw13r27mxuny',
                    creation_date: '2024-04-01T13:36:38Z',
                    last_update_date: '2024-04-01T17:40:16Z',
                    is_retention_enabled: true,
                    grace_period_in_days: 60,
                    plans: [
                      {
                        amount_by_installment: {
                          value: 1020.33,
                          description: 'R$ 1020,33',
                          type: 'money'
                        },
                        installments: {
                          value: 2,
                          description: '2x',
                          type: 'integer'
                        },
                        total: {
                          value: 2040.67,
                          description: 'R$ 2040,67',
                          type: 'money'
                        },
                        type: 'GraceWithInterest'
                      },
                      {
                        amount_by_installment: {
                          value: 502.33,
                          description: 'R$ 502,33',
                          type: 'money'
                        },
                        installments: {
                          value: 4,
                          description: '4x',
                          type: 'integer'
                        },
                        total: {
                          value: 2040.67,
                          description: 'R$ 2040,67',
                          type: 'money'
                        },
                        type: 'Regular'
                      }
                    ],
                    current_installment: {
                      installment_number: 1,
                      start_date: '2024-04-01T00:00:00Z',
                      due_date: '2026-05-03',
                      days_until_due_date: 0,
                      is_due_soon: false,
                      days_overdue: 157,
                      is_overdue: false,
                      status: 'open',
                      paid_amount: 0,
                      initial_amount: 225081,
                      future_balance: 225081,
                      present_balance: 225081,
                      interest_economy_amount: 0,
                      penalty_fee_amount: 0,
                      penalty_interest_amount: 0,
                      late_payment_interest_amount: 0,
                      total_debt_amount: 4392
                    },
                    next_installment: {
                      installment_number: 2,
                      start_date: '2024-05-04T00:00:00Z',
                      due_date: '2024-06-03T00:00:00Z',
                      future_balance: 225081,
                      present_balance: 225081
                    },
                    overdue_installment: {
                      overdue_installments: [
                        {
                          installment_number: 1,
                          due_date: '2024-05-03',
                          present_balance: 225081
                        }
                      ],
                      total_present_balance: 1350484,
                      days_overdue: 157,
                      penalty_fee_amount: 0,
                      penalty_interest_amount: 0,
                      late_payment_interest_amount: 0
                    },
                    renegotiation_summary: {
                      status: 'Disbursed',
                      status_description: 'Renegociação em análise',
                      loan_renegotiation_contract_id: 'jr3kfbqmc1ag9asfvsn8wzg3u',
                      contract_signature_date: '2024-08-01',
                      creation_date: '2024-08-01'
                    }
                  }
                ]
              }
            ]
          },
          trace_key: 'ff31c91c7a6d4ebfa640c7e1b',
          is_valid: true
        })
      }
    ]
  },
  {
    scenario: 'Renegociação em processo sem carência',
    mocks: [
      {
        method: 'GET',
        url: '**/experience-bff-service/api/web/v1/home**',
        status: 200,
        delay: 0,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({
          content: {
            sections: [
              {
                id: 'loans',
                title: 'Home.Sections.Loans.Title',
                elements: [
                  {
                    id: 'jqqu3e377j4u9q7f77ufbr7yw',
                    type: 'current_loan',
                    status: 'active',
                    status_description: 'Ativo',
                    credit_amount: 2022864,
                    total_of_installment: 10,
                    initial_installment_amount: 225081,
                    installment_plan_id: 'jr3kfct7fhqp4bw13r27mxuny',
                    creation_date: '2024-04-01T13:36:38Z',
                    last_update_date: '2024-04-01T17:40:16Z',
                    is_retention_enabled: true,
                    grace_period_in_days: 0,
                    plans: [
                      {
                        amount_by_installment: {
                          value: 502.33,
                          description: 'R$ 502,33',
                          type: 'money'
                        },
                        installments: {
                          value: 4,
                          description: '4x',
                          type: 'integer'
                        },
                        type: 'Regular'
                      }
                    ],
                    current_installment: {
                      installment_number: 1,
                      start_date: '2024-04-01T00:00:00Z',
                      due_date: '2026-05-03',
                      days_until_due_date: 0,
                      is_due_soon: false,
                      days_overdue: 157,
                      is_overdue: false,
                      status: 'open',
                      paid_amount: 0,
                      initial_amount: 225081,
                      future_balance: 225081,
                      present_balance: 225081,
                      interest_economy_amount: 0,
                      penalty_fee_amount: 0,
                      penalty_interest_amount: 0,
                      late_payment_interest_amount: 0,
                      total_debt_amount: 4392
                    },
                    next_installment: {
                      installment_number: 2,
                      start_date: '2024-05-04T00:00:00Z',
                      due_date: '2024-06-03T00:00:00Z',
                      future_balance: 225081,
                      present_balance: 225081
                    },
                    overdue_installment: {
                      overdue_installments: [
                        {
                          installment_number: 1,
                          due_date: '2024-05-03',
                          present_balance: 225081
                        }
                      ],
                      total_present_balance: 1350484,
                      days_overdue: 157,
                      penalty_fee_amount: 0,
                      penalty_interest_amount: 0,
                      late_payment_interest_amount: 0
                    },
                    renegotiation_summary: {
                      status: 'Disbursed',
                      status_description: 'Renegociação em análise',
                      loan_renegotiation_contract_id: 'jr3kfbqmc1ag9asfvsn8wzg3u',
                      contract_signature_date: '2024-08-01',
                      creation_date: '2024-08-01'
                    }
                  }
                ]
              }
            ]
          },
          trace_key: 'ff31c91c7a6d4ebfa640c7e1b',
          is_valid: true
        })
      }
    ]
  },
  {
    scenario: 'Renegociação ativa com carência',
    mocks: [
      {
        method: 'GET',
        url: '**/experience-bff-service/api/web/v1/home**',
        status: 200,
        delay: 0,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({
          content: {
            sections: [
              {
                id: 'loans',
                title: 'Home.Sections.Loans.Title',
                elements: [
                  {
                    id: 'jqqu3e377j4u9q7f77ufbr7yw',
                    type: 'current_loan',
                    status: 'active',
                    status_description: 'Ativo',
                    credit_amount: 2022864,
                    total_of_installment: 10,
                    initial_installment_amount: 225081,
                    installment_plan_id: 'jr3kfct7fhqp4bw13r27mxuny',
                    creation_date: '2024-04-01T13:36:38Z',
                    last_update_date: '2024-04-01T17:40:16Z',
                    is_retention_enabled: true,
                    grace_period_in_days: 60,
                    plans: [
                      {
                        amount_by_installment: {
                          value: 1020.33,
                          description: 'R$ 1020,33',
                          type: 'money'
                        },
                        installments: {
                          value: 2,
                          description: '2x',
                          type: 'integer'
                        },
                        total: {
                          value: 2040.67,
                          description: 'R$ 2040,67',
                          type: 'money'
                        },
                        type: 'GraceWithInterest'
                      },
                      {
                        amount_by_installment: {
                          value: 502.33,
                          description: 'R$ 502,33',
                          type: 'money'
                        },
                        installments: {
                          value: 4,
                          description: '4x',
                          type: 'integer'
                        },
                        total: {
                          value: 2040.67,
                          description: 'R$ 2040,67',
                          type: 'money'
                        },
                        type: 'Regular'
                      }
                    ],
                    current_installment: {
                      installment_number: 1,
                      start_date: '2024-04-01T00:00:00Z',
                      due_date: '2026-05-03',
                      days_until_due_date: 0,
                      is_due_soon: false,
                      days_overdue: 157,
                      is_overdue: false,
                      status: 'open',
                      paid_amount: 0,
                      initial_amount: 225081,
                      future_balance: 225081,
                      present_balance: 225081,
                      interest_economy_amount: 0,
                      penalty_fee_amount: 0,
                      penalty_interest_amount: 0,
                      late_payment_interest_amount: 0,
                      total_debt_amount: 4392
                    },
                    next_installment: {
                      installment_number: 2,
                      start_date: '2024-05-04T00:00:00Z',
                      due_date: '2024-06-03T00:00:00Z',
                      future_balance: 225081,
                      present_balance: 225081
                    },
                    overdue_installment: {
                      overdue_installments: [
                        {
                          installment_number: 1,
                          due_date: '2024-05-03',
                          present_balance: 225081
                        }
                      ],
                      total_present_balance: 1350484,
                      days_overdue: 157,
                      penalty_fee_amount: 0,
                      penalty_interest_amount: 0,
                      late_payment_interest_amount: 0
                    },
                    renegotiation_summary: {
                      status: 'Active',
                      status_description: 'Renegociação ativa',
                      loan_renegotiation_contract_id: 'jr3kfbqmc1ag9asfvsn8wzg3u',
                      contract_signature_date: '2024-04-01'
                    }
                  }
                ]
              }
            ]
          },
          trace_key: 'ff31c91c7a6d4ebfa640c7e1b',
          is_valid: true
        })
      }
    ]
  },
  {
    scenario: 'Renegociação ativa sem carência',
    mocks: [
      {
        method: 'GET',
        url: '**/experience-bff-service/api/web/v1/home**',
        status: 200,
        delay: 0,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({
          content: {
            sections: [
              {
                id: 'loans',
                title: 'Home.Sections.Loans.Title',
                elements: [
                  {
                    id: 'jqqu3e377j4u9q7f77ufbr7yw',
                    type: 'current_loan',
                    status: 'active',
                    status_description: 'Ativo',
                    credit_amount: 2022864,
                    total_of_installment: 10,
                    initial_installment_amount: 225081,
                    installment_plan_id: 'jr3kfct7fhqp4bw13r27mxuny',
                    creation_date: '2024-04-01T13:36:38Z',
                    last_update_date: '2024-04-01T17:40:16Z',
                    is_retention_enabled: true,
                    grace_period_in_days: 0,
                    plans: [
                      {
                        amount_by_installment: {
                          value: 502.33,
                          description: 'R$ 502,33',
                          type: 'money'
                        },
                        installments: {
                          value: 4,
                          description: '4x',
                          type: 'integer'
                        },
                        type: 'Regular'
                      }
                    ],
                    current_installment: {
                      installment_number: 1,
                      start_date: '2024-04-01T00:00:00Z',
                      due_date: '2026-05-03',
                      days_until_due_date: 0,
                      is_due_soon: false,
                      days_overdue: 157,
                      is_overdue: false,
                      status: 'open',
                      paid_amount: 0,
                      initial_amount: 225081,
                      future_balance: 225081,
                      present_balance: 225081,
                      interest_economy_amount: 0,
                      penalty_fee_amount: 0,
                      penalty_interest_amount: 0,
                      late_payment_interest_amount: 0,
                      total_debt_amount: 4392
                    },
                    next_installment: {
                      installment_number: 2,
                      start_date: '2024-05-04T00:00:00Z',
                      due_date: '2024-06-03T00:00:00Z',
                      future_balance: 225081,
                      present_balance: 225081
                    },
                    overdue_installment: {
                      overdue_installments: [
                        {
                          installment_number: 1,
                          due_date: '2024-05-03',
                          present_balance: 225081
                        }
                      ],
                      total_present_balance: 1350484,
                      days_overdue: 157,
                      penalty_fee_amount: 0,
                      penalty_interest_amount: 0,
                      late_payment_interest_amount: 0
                    },
                    renegotiation_summary: {
                      status: 'Active',
                      status_description: 'Renegociação ativa',
                      loan_renegotiation_contract_id: 'jr3kfbqmc1ag9asfvsn8wzg3u',
                      contract_signature_date: '2024-04-01'
                    }
                  }
                ]
              }
            ]
          },
          trace_key: 'ff31c91c7a6d4ebfa640c7e1b',
          is_valid: true
        })
      }
    ]
  }
]
