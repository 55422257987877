import { useRouter } from 'next/router'
import { Stack } from '@dlpco/fluid-layout'
import { Text } from '@dlpco/ginga-stone'
import { DogIllustration } from 'design-elements/shared/illustrations'

import { Heimdall } from '~/domains/platform/core/heimdall'
import { Box } from '~/domains/platform/design-system'
import { type PaymentAccount } from '~/domains/platform/infra/deus-ex-machina/types'
import { analytics } from '~/domains/platform/lib/analytics'
import BlackBird from '~/domains/platform/lib/blackbird'
import { Choose, For } from '~/domains/platform/lib/utilities-components'
import { CardAccount } from '~/ui/components/cards/card-account/card-account'
import { EmptyState } from '~/ui/components/utils/empty-state/empty-state'
import { useOpenFinanceContext } from '~/ui/hooks/open-finance'

import { AccountInfo } from './account-info'

interface SelectAccountProps {
  currentAccount: PaymentAccount
  accounts: PaymentAccount[]
}

export function SelectAccount({ currentAccount, accounts }: SelectAccountProps) {
  const { data, saveData } = useOpenFinanceContext()
  const chosenAccount = data.chosenAccount || {}

  const { push } = useRouter()

  const query = BlackBird.getQuery()

  const filteredAccount = [currentAccount, chosenAccount]
    .concat(accounts.filter(acc => acc?.id !== currentAccount.id && acc?.id !== chosenAccount?.id))
    .filter(item => item?.status === 'ACTIVE')
    .filter((item, idx, a) => a.indexOf(item) === idx)

  const handleChoosePaymentAccount = (selectedAccount: PaymentAccount) => {
    saveData({ ...data, chosenAccount: selectedAccount })

    if (Heimdall.pass(['opf_transmission_web'])) {
      BlackBird.travelTo({ pathname: '/open-finance/compartilhar-dados/confirmacao', query: { ...query } })
    } else {
      analytics.sendEvent('OPF - authentication page viewed', {
        event: 'Cliente iniciou o fluxo de consentimento de transação via ITP (OPF)'
      })

      Heimdall.pass(['open_finance_payment_initiation_web_v2'])
        ? push({ pathname: '/open-finance/v2/pagamento/consentimento', query: { ...query } })
        : push({ pathname: '/open-finance/pagamento/consentimento', query: { ...query } })
    }
  }

  return (
    <Box p="0 1rem 1rem 1rem" maxWidth="48rem">
      <Choose>
        <Choose.When condition={filteredAccount.length === 0}>
          <EmptyState illustration={<DogIllustration />} title="Aconteceu um erro inesperado" />
        </Choose.When>
        <Choose.Otherwise>
          <Stack space="1.5rem">
            <Text style={{ paddingBottom: '0.25rem' }} weight="bold" size="large">
              Selecione uma conta
            </Text>

            <For
              of={filteredAccount}
              render={account => (
                <CardAccount
                  onClick={() => handleChoosePaymentAccount(account)}
                  title={account.ownerName}
                  key={account.ownerId}
                  subtitle={
                    <AccountInfo
                      accountNumber={account.accountCode}
                      document={account.ownerDocument}
                      active={account.id === currentAccount.id}
                      chosenAccount={account.id === chosenAccount?.id}
                    />
                  }
                />
              )}
            />
          </Stack>
        </Choose.Otherwise>
      </Choose>
    </Box>
  )
}
