import { Stack } from '@dlpco/fluid-layout'
import { HouseIllustration, UserPfIllustration } from 'design-elements/shared/illustrations'

import { Box, Text } from '~/domains/platform/design-system'
import { type Subject } from '~/domains/platform/infra/deus-ex-machina/ports'
import { If } from '~/domains/platform/lib/utilities-components'
import { CardAccount } from '~/ui/components/cards/card-account/card-account'
import { useWizardProps } from '~/ui/components/modals/wizard/use-wizard-props'

import { WizardSteps } from '../steps'

const { FORM, CONFIRMATION } = WizardSteps

export function ChooseKind(props: { hasPersonalAccount: boolean; subject: Subject }) {
  const { hasPersonalAccount, subject } = props
  const { goTo } = useWizardProps()

  return (
    <Box maxWidth="420px">
      <Text fontSize="xLarge" marginBottom="2.5rem">
        Para quem é esta conta?
      </Text>
      <Stack space="2rem">
        <CardAccount
          title="Para meu negócio"
          subtitle="Abrir uma conta de um CNPJ que você é sócio"
          icon={<HouseIllustration />}
          onClick={() => goTo(FORM)}
        />

        <If condition={!hasPersonalAccount}>
          <CardAccount
            title="Para mim"
            subtitle="Abrir sua conta pessoa física"
            icon={<UserPfIllustration />}
            onClick={() =>
              goTo(CONFIRMATION, { entity: { document: subject.document, fullName: subject.displayName } })
            }
          />
        </If>
      </Stack>
    </Box>
  )
}
