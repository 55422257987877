import { Text } from '@stone-payments/jade'

export function NewAccountSwitch({ name, document }: { name: string; document: string }) {
  return (
    <svg width="260" height="110" viewBox="0 0 260 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_dd_308_65)">
        <rect width="228" height="67.9529" transform="translate(29 11)" fill="white" />
        <foreignObject x="50" y="20" width="150" height="30">
          <Text
            style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
            variant="text-large"
            weight="semibold"
          >
            {name}
          </Text>
        </foreignObject>
        <mask
          id="mask0_308_65"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="217"
          y="28"
          width="19"
          height="19"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M221.725 35.8871C222.045 35.567 222.564 35.567 222.884 35.8871L226.599 39.6016L230.313 35.8871C230.633 35.567 231.152 35.567 231.472 35.8871C231.792 36.2072 231.792 36.7261 231.472 37.0462L227.178 41.3403C227.024 41.494 226.816 41.5803 226.599 41.5803C226.381 41.5803 226.173 41.494 226.019 41.3403L221.725 37.0462C221.405 36.7261 221.405 36.2072 221.725 35.8871Z"
            fill="black"
          />
        </mask>
        <g mask="url(#mask0_308_65)">
          <rect x="217.659" y="28.9412" width="17.8824" height="17.8824" fill="#000E18" fillOpacity="0.593" />
        </g>
        <foreignObject x="50" y="45" width="150" height="20">
          <Text variant="text-small" color="medium">
            {document}
          </Text>
        </foreignObject>
        <g filter="url(#filter1_i_308_65)">
          <path
            d="M233.272 56.5783L230.362 43.9694L238.504 56.7684L237.327 57.4329L235.212 54.1072L233.272 56.5783Z"
            fill="#45505E"
          />
          <path
            d="M240.792 52.3303L230.362 43.9691L238.504 56.7681L239.68 56.1036L237.565 52.7779L240.792 52.3303Z"
            fill="#45505E"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_dd_308_65"
          x="0.388236"
          y="0.270589"
          width="285.224"
          height="125.176"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology radius="7.15294" operator="erode" in="SourceAlpha" result="effect1_dropShadow_308_65" />
          <feOffset dy="17.8824" />
          <feGaussianBlur stdDeviation="17.8824" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_308_65" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.447059" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_308_65" result="effect2_dropShadow_308_65" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_308_65" result="shape" />
        </filter>
        <filter
          id="filter1_i_308_65"
          x="230.362"
          y="43.9691"
          width="10.4305"
          height="13.4639"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-0.894118" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.188235 0 0 0 0 0.215686 0 0 0 0 0.258824 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_308_65" />
        </filter>
      </defs>
    </svg>
  )
}
