import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { Grid, Stack } from '@dlpco/fluid-layout'
import { Alert, Button, Card, Icon } from '@dlpco/ginga-stone'
import { type AxiosError } from 'axios'
import { PaperDocumentsErrorIllustration } from 'design-elements/shared/illustrations'

import { useBalance } from '~/domains/banking/ports/hooks/use-balance'
import { Heimdall } from '~/domains/platform/core/heimdall'
import { Box, Flex, Text } from '~/domains/platform/design-system'
import { Dimmer } from '~/domains/platform/design-system/dimmer'
import { Divider } from '~/domains/platform/design-system/divider/divider'
import { Field } from '~/domains/platform/design-system/field/field'
import { Loader } from '~/domains/platform/design-system/loader/loader'
import { analytics } from '~/domains/platform/lib/analytics'
import BlackBird from '~/domains/platform/lib/blackbird'
import { timeMachine } from '~/domains/platform/lib/time-machine'
import { Choose, If } from '~/domains/platform/lib/utilities-components'
import { date } from '~/lib/helpers/utils/daydash'
import { isomorphicPush } from '~/lib/helpers/utils/isomorphic-push'
import { stringFormat } from '~/lib/helpers/utils/string-format'
import { type AuthConsent } from '~/lib/types/open-finance/ConsentPayment'
import { EmptyState } from '~/ui/components/utils/empty-state/empty-state'
import { useOpenFinanceContext } from '~/ui/hooks/open-finance'
import { useStatus } from '~/ui/hooks/utils/service/use-status'
import { theme } from '~/ui/theme/theme'

const TYPE_ERRORS = ['srn:error:validation', 'srn:error:unauthorized', 'srn:error:not_found']

interface PaymentConsentProps {
  consentData?: AuthConsent
  consentStatus?: any
  consentError?: AxiosError
  consentRevalidate?: any
}

export function PaymentConsent({ consentData, consentStatus, consentError, consentRevalidate }: PaymentConsentProps) {
  const { data, saveData } = useOpenFinanceContext()
  const { chosenAccount } = data

  const [genericError, setGenericError] = useState(false)

  const { push } = useRouter()

  const query = BlackBird.getQuery()

  const [balance, balanceLoading, revalidate] = useBalance(chosenAccount.id)

  const isPaymentConsentReady = useStatus([consentStatus], ['resolved', 'refreshing', 'idle', 'rejected'])

  const handleGoToConfirmConsent = async () => {
    await consentRevalidate()

    if (!consentError && consentData?.consent?.status !== 'REJECTED') {
      saveData({ ...data, consent: consentData?.consent })

      Heimdall.pass(['open_finance_payment_initiation_web_v2'])
        ? push({ pathname: '/open-finance/v2/pagamento/confirmar-consentimento', query: { ...query } })
        : push({ pathname: '/open-finance/pagamento/confirmar-consentimento', query: { ...query } })
    }
  }

  const handleGoToCancelConsent = () => {
    saveData({ ...data, consent: consentData?.consent })
    Heimdall.pass(['open_finance_payment_initiation_web_v2'])
      ? push({ pathname: '/open-finance/pagamento/cancelar-consentimento', query: { ...query } })
      : push({ pathname: '/open-finance/pagamento/cancelar-consentimento', query: { ...query } })
  }

  useEffect(() => {
    if (consentError) {
      const { response } = consentError as AxiosError
      const { status, data } = response || {}

      const isNotFound = status == 404 || TYPE_ERRORS.includes(data?.type)
      const isInvalid = status === 400 || status === 403 || TYPE_ERRORS.includes(data?.type)

      if (isNotFound || isInvalid) {
        setGenericError(true)

        analytics.sendEvent('OPF - itp confirmation page viewed', {
          event: 'Consentimento de uma transação inválido ou não encontrado'
        })
      }
    }
    analytics.sendEvent('OPF - itp confirmation page viewed', {
      event: 'Cliente visualizou os dados para consentimento da transação via ITP (OPF)'
    })
  }, [consentError])

  const { consent } = consentData || {}
  const shouldNotBalance = Boolean(consent?.amount && balance < consent?.amount)
  const isReady = Boolean(
    isPaymentConsentReady && balanceLoading === false && (Boolean(consentError) || consentData !== undefined)
  )
  const hasGenericError = consent?.status !== 'AWAITING_AUTHORISATION' || genericError
  const awaithAthorisation = consent?.status !== 'AWAITING_AUTHORISATION' && !genericError

  return (
    <Choose>
      <Choose.When condition={isReady}>
        <Box p="0 1rem 1rem 1rem" maxWidth="48rem">
          <Stack space="1.5rem">
            <Text pb="0.25rem" fontWeight="bold" fontSize="large">
              <Choose>
                <Choose.When condition={awaithAthorisation}>O pagamento não pode mais ser feito.</Choose.When>
                <Choose.When condition={consent?.status === 'AWAITING_AUTHORISATION'}>
                  Confirme os dados da sua transferência por Pix
                </Choose.When>
              </Choose>
            </Text>

            <Choose>
              <Choose.When condition={consent?.status === 'AWAITING_AUTHORISATION' && shouldNotBalance}>
                <Alert color="warning" heading="Saldo insuficiente">
                  Seu saldo em conta é insuficiente para esta transação. Você pode alterar sua conta ou cancelar a
                  transação.
                  <Button
                    onClick={() => isomorphicPush('/open-finance/pagamento/selecionar-conta')}
                    variant="contentOnly"
                    color="primary"
                    css={{ color: theme.colors.yellow, fontWeight: 'bold' }}
                  >
                    Alterar conta
                  </Button>
                </Alert>
              </Choose.When>
              <Choose.When
                condition={consent?.status === 'REJECTED' && consent?.paymentTransactionStatus === 'EXPIRED'}
              >
                <Alert color="warning" heading="Tempo de confirmação ou cancelamento expirado">
                  O prazo pra você confirmar ou cancelar a transação é de 5 minutos.
                </Alert>
              </Choose.When>
              <Choose.When condition={hasGenericError}>
                <Card>
                  <EmptyState
                    illustration={<PaperDocumentsErrorIllustration />}
                    title="Houve um problema com o pagamento"
                    body="Não foi possível prosseguir com o pagamento."
                    action={() => BlackBird.goHome()}
                    callToAction="Tentar novamente"
                  />
                </Card>
              </Choose.When>
            </Choose>

            <Choose.When condition={!hasGenericError}>
              <Flex flexDirection="column">
                <Field
                  label="Valor"
                  value={stringFormat.currency(consent?.amount)}
                  fontSize="medium"
                  renderProps={{ fontSize: theme.fontSizes.xLarge }}
                />

                <Flex justifyContent="space-between">
                  <Text
                    fontWeight="bold"
                    fontSize="medium"
                    color={balance < (consent?.amount || 0) ? theme.colors.yellow : theme.colors.mediumGray2}
                  >
                    Seu saldo atual é {stringFormat.currency(balance as number)}
                  </Text>
                  <div
                    role="button"
                    color={theme.colors.black}
                    onClick={async () => {
                      if (typeof revalidate === 'function') {
                        await revalidate()
                      }
                    }}
                  >
                    <Icon use="action-arrow-refresh-outline" inheritColor size="large" />
                  </div>
                </Flex>
              </Flex>

              <Flex>
                <Field
                  label="Data de Pagamento"
                  value={
                    consent?.scheduledDate
                      ? date.simpleFormat('DD [de] MMMM [de] YYYY', consent?.scheduledDate)
                      : date.simpleFormat('DD [de] MMMM [de] YYYY', consent?.createdAt)
                  }
                  mr={6}
                />
                <If condition={!consent?.scheduledDate}>
                  <Field label="Horário" value={timeMachine(consent?.createdAt).format('smallHour')} />
                </If>
              </Flex>

              <Divider />

              <Field label="Nome do destino" value={consent?.creditorEntityName} />
              <Field label="CNPJ/CPF" value={stringFormat.hideDocument(consent?.creditorEntityCpfCnpj || '')} />

              <Divider />

              <Field label="Nome de origem" value={chosenAccount.ownerName} />
              <Flex>
                <Field
                  label="Conta de origem"
                  value={stringFormat.accountNumber(chosenAccount.accountCode)}
                  paddingRight="2rem"
                />
                <If condition={!hasGenericError}>
                  <Button
                    disabled={hasGenericError}
                    onClick={() => isomorphicPush('/open-finance/pagamento/selecionar-conta')}
                    variant="contentOnly"
                    color="primary"
                  >
                    Alterar conta
                  </Button>
                </If>
              </Flex>

              <Divider />

              <Field
                label="Tarifa"
                value={consent && consent?.fee > 0 ? stringFormat.currency(consent.fee) : 'Grátis'}
              />
              <Field label="Tipo" value={consent?.type === 'PIX' ? 'Pix - Open Banking' : 'N/A'} />

              <Divider />

              <Grid gutter="1rem" min="20ch">
                <Choose>
                  <Choose.When condition={hasGenericError}>
                    <Button size="large" onClick={() => BlackBird.goHome()}>
                      Ir para o início
                    </Button>
                  </Choose.When>
                  <Choose.Otherwise>
                    <Button size="large" onClick={handleGoToConfirmConsent} disabled={hasGenericError}>
                      Confirmar Pagamento
                    </Button>
                    <Button color="neutral" size="large" onClick={handleGoToCancelConsent} disabled={hasGenericError}>
                      Cancelar
                    </Button>
                  </Choose.Otherwise>
                </Choose>
              </Grid>
            </Choose.When>
          </Stack>
        </Box>
      </Choose.When>
      <Choose.When condition={!isReady}>
        <Dimmer isVisible>
          <Loader />
        </Dimmer>
      </Choose.When>
    </Choose>
  )
}
