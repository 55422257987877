import { PaperDocumentsErrorIllustration } from 'design-elements/shared/illustrations'

import { Box } from '~/domains/platform/design-system'
import BlackBird from '~/domains/platform/lib/blackbird'
import { EmptyState } from '~/ui/components/utils/empty-state/empty-state'
import { useOpenFinanceContext } from '~/ui/hooks/open-finance'

export function ConsentError() {
  const { data } = useOpenFinanceContext()

  const { title, subtitle, callToAction, action } = data?.error ?? {}

  return (
    <Box p="0 1rem 1rem 1rem" maxWidth="48rem">
      <EmptyState
        illustration={<PaperDocumentsErrorIllustration />}
        title={title}
        body={subtitle}
        action={() => (action ? action() : BlackBird.goHome())}
        callToAction={callToAction}
      />
    </Box>
  )
}
