export function CorreiosIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={18} viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="40" height="" fill="white" />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4852 0.016337C15.1436 -0.00163809 12.8525 0.016341 10.5109 0C12.0044 2.04589 13.5895 3.92184 15.0864 5.95956C16.3021 4.48887 17.312 3.01819 18.5294 1.53769C19 0.841568 18.3039 -0.016345 17.4885 0.016337H17.4852Z"
        fill="#053D6B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.861 11.9174H10.5059C12.6302 9.24569 16.4262 4.18488 18.5489 1.51476C18.9182 0.776143 18.4035 0.168261 17.6763 0.00648499C18.9836 0.0342647 19.4379 0.0179237 19.9493 0.496715C21.2435 2.14879 22.4887 3.86296 23.7829 5.51503C24.1669 5.98565 23.9986 6.31246 23.6113 6.77165C22.5056 8.21619 21.3982 9.66019 20.2892 11.1036C19.935 11.5484 19.424 11.8408 18.861 11.9207V11.9174Z"
        fill="#0D73A8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.46384 17.9409C8.80551 17.9588 11.1308 17.9098 13.4709 17.9278C11.9789 15.8819 10.4396 14.037 8.94604 11.9911C7.73027 13.4618 6.68608 14.9505 5.46867 16.4244C5.09283 17.0895 5.54874 17.8248 6.46384 17.9409Z"
        fill="#B56B1C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.13949 6.03337H13.4946C11.3703 8.70513 7.5743 13.7659 5.4516 16.436C5.08229 17.1747 5.59704 17.7826 6.32422 17.9443C5.01693 17.9166 4.56265 17.9329 4.05118 17.4541C2.75697 15.802 1.51178 14.0878 0.217576 12.4358C-0.166438 11.9651 0.00187483 11.6383 0.380986 11.1792L3.70475 6.84715C4.06038 6.40542 4.57122 6.11608 5.13295 6.03826L5.13949 6.03337Z"
        fill="#F5CC0D"
      />
    </svg>
  )
}
