export function UpsellBannerIllustration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={89} height={96} fill="none" {...props}>
      <path
        d="M-2.395 39.554s19.55-2.298 28.832-9.874c6.486-5.292 10.514-13.032 9.05-20.824a11.59 11.59 0 01-8.977 1.298 11.592 11.592 0 01-7.214-5.602 11.558 11.558 0 01-1.301-3.73C14.762-.315 11.03-.315 7.488 1.086a15.37 15.37 0 00-3.209 1.727C1.595 4.697-.329 7.346-1.26 10.263c-.143.444-.26.895-.354 1.348a12.97 12.97 0 00-.231 3.764c.297 1.848-.55 24.18-.55 24.18z"
        fill="#FE6A34"
      />
      <path
        d="M17.455 26.21C10.757 28.857 3.39 26.494-.02 20.93a12.943 12.943 0 01-.977-1.957 12.596 12.596 0 01-.867-5.538c.039-.612.124-1.222.248-1.822.095-.455.213-.905.355-1.349.93-2.918 2.854-5.565 5.539-7.45a15.612 15.612 0 013.208-1.727C11.031-.315 14.763-.313 17.995.824c.502.176.996.38 1.474.614a13.386 13.386 0 013.064 2.04 12.677 12.677 0 013.405 4.846c.242.605.431 1.215.573 1.833 1.491 6.456-2.265 13.367-9.056 16.052z"
        fill="#FFCBD9"
      />
      <path
        d="M.255 12.585L-3.31 13.99c-2.984 1.175-4.462 4.573-3.285 7.553 1.176 2.98 4.58 4.455 7.564 3.28l3.565-1.404L.255 12.585z"
        fill="#FFCBD9"
      />
      <path
        d="M30.302 22.773a5.062 5.062 0 01-1.734-.314.249.249 0 11.17-.469c1.776.645 3.528.206 5.206-1.306a.249.249 0 01.353.018.248.248 0 01-.018.353c-1.262 1.138-2.614 1.718-3.977 1.718zM30.588 25.493c-.566 0-1.376-.131-2.357-.651a.25.25 0 11.235-.44c1.71.907 2.823.498 2.834.493a.249.249 0 01.183.464c-.026.011-.346.134-.895.134z"
        fill="#222730"
      />
      <path
        d="M19.47 1.437c-.339.309-.711.611-1.118.902-1.213.869-2.72 1.625-4.415 2.167-3.442 1.1-6.764 1.02-8.767-.014-.115 1.312-.255 4.854-3.602 7.747-1.083.936-2.327 1.138-3.432 1.197.039-.612.123-1.222.248-1.822.095-.455.213-.905.355-1.349.93-2.918 2.854-5.565 5.539-7.45a15.612 15.612 0 013.208-1.727C11.031-.315 14.762-.313 17.995.824c.502.174.996.38 1.474.613z"
        fill="#FE6A34"
      />
      <path
        d="M9.444 8.278c.702-.577 1.034-1.332.742-1.687-.293-.355-1.099-.176-1.801.4-.702.577-1.034 1.333-.742 1.688.293.355 1.1.175 1.801-.401zM16.925 7.357c.629-.656.868-1.445.535-1.763-.332-.318-1.112-.044-1.74.613-.628.656-.868 1.445-.535 1.763.333.318 1.112.043 1.74-.613z"
        fill="#FE6A34"
      />
      <path
        d="M51.878 41.38c2.977-7.172 6.775-18.4 10.475-20.547 3.836-2.224 6.935-10.456 8.988-13.805 2.08-3.393 4.844-2.908 6.064-1.05.66 1.005.559 2.56.357 3.784-.263 1.61-1.293 4.835-1.716 6.135.94-1.029 1.59-2.262 2.557-4.121 1.22-2.343 5.108-1.928 5.618 1.515.24 1.649-.22 3.618-.737 5.246 1.711-2.28 4.654-1.758 5.373.753.322 1.128.04 3.466-.22 4.729-2.706 13.147-9.104 15.595-15.15 24.51"
        fill="#FFCBD9"
      />
      <path
        d="M79.518 24.869a.251.251 0 01-.176-.074.247.247 0 010-.352c.023-.023 2.295-2.326 3.916-6.007a.25.25 0 01.458.2c-1.661 3.773-3.925 6.065-4.021 6.16a.25.25 0 01-.177.073z"
        fill="#222730"
      />
      <path
        d="M74.837 46.5L54.489 77.113a11.472 11.472 0 01-10.242 5.851 11.474 11.474 0 01-9.47-5.337L21.381 51.439c-3.383-5.351-1.78-12.426 3.579-15.803 5.359-3.378 12.445-1.778 15.827 3.573l6.27 9.917 4.568-8.141 3.194-5.686c3.097-5.519 10.09-7.485 15.617-4.392 5.53 3.092 7.5 10.075 4.4 15.593z"
        fill="#FFCBD9"
      />
      <path
        d="M37.154 65.987a.251.251 0 01-.25-.235l-.729-13.253a.25.25 0 01.236-.263.254.254 0 01.264.236l.729 13.253a.25.25 0 01-.237.263h-.013z"
        fill="#222730"
      />
      <path d="M36.427 51.4l-.69 15.409h-3.63l-4.152-6.89 8.472-8.519z" fill="#222730" />
      <path d="M29.092 59.984l16.8 35.926h-50.09l1.076-38.466 32.214 2.54z" fill="#008E5A" />
      <path
        d="M49.748 14.667l2.6 4.349.53.887.283.474 10 16.72a3.35 3.35 0 004.593 1.155l11.65-6.948a3.337 3.337 0 001.156-4.583L67.146 4.295a3.35 3.35 0 00-4.59-1.156l-6.095 3.635-5.555 3.312a3.338 3.338 0 00-1.158 4.581z"
        fill="#222730"
      />
      <path
        d="M49.748 14.668l2.6 4.349 4.502-7.568 1.96-3.3c-.396-.123-1.283-.667-2.35-1.376l-5.556 3.312a3.341 3.341 0 00-1.157 4.583zM52.88 19.903l.282.474c1.305-2.165 2.607-4.33 3.912-6.496.066-.114-.017-.276-.127-.324-.133-.055-.254.012-.324.126-.431.722-.869 1.443-1.3 2.162l-2.443 4.058z"
        fill="#42EC9A"
      />
      <path
        d="M71.392 36.081l11.79-7.03a3.34 3.34 0 001.158-4.583L70.926 2.04a3.351 3.351 0 00-4.59-1.156l-11.79 7.03a3.34 3.34 0 00-1.158 4.583l13.414 22.428a3.35 3.35 0 004.59 1.156z"
        fill="#00A868"
      />
      <path d="M68.058 32.49l13.535-8.07L69.642 4.437l-13.535 8.07 11.95 19.983z" fill="#42EC9A" />
      <path d="M80.907 24.247l-.917.547L68.552 5.67l.917-.547 11.438 19.124z" fill="#FCFCFC" stroke="#FCFCFC" />
      <path
        d="M61.636 7.097a.698.698 0 100-1.397.698.698 0 000 1.397zM70.21 21.48a.22.22 0 00.316.055 3.675 3.675 0 00.866-4.959 3.688 3.688 0 00-4.902-1.178.22.22 0 00-.075.311l.757 1.152c.07.108.21.14.325.084a1.843 1.843 0 012.355.64c.516.786.353 1.82-.348 2.412a.252.252 0 00-.051.33l.757 1.153zM65.743 18.16a.231.231 0 00-.273.178l-.306 1.948a.463.463 0 00.068.327.46.46 0 00.273.193l1.91.493a.231.231 0 00.274-.177.223.223 0 00-.034-.173l-1.769-2.69a.212.212 0 00-.143-.099z"
        fill="#222730"
      />
      <path
        d="M66.272 41.846l-5.961 3.836-8.638-4.523c2.966-6.59.315-13.448-2.054-17.775-1.196-2.186-.02-4.767 2.167-4.824 1.756-.046 3.573 1.169 5.744 6.227.353.822.775 3.052 2.08 3.81.132.076.263.153.401.213 6.47 2.766 6.825 10.9 6.261 13.036z"
        fill="#FFCBD9"
      />
      <path
        d="M39.545 44.044l-1.161 9.143c-.651 5.111-5.328 8.727-10.445 8.079L3.593 58.18l7.367-29.555 10.078 2.455 5.644 1.374 4.773 1.161a9.26 9.26 0 011.606.35 9.262 9.262 0 013.975 2.465 9.29 9.29 0 012.51 7.614z"
        fill="#00A868"
      />
      <path
        d="M26.12 40.204a4.093 4.093 0 01.201 5.791 4.108 4.108 0 01-5.8.202l5.599-5.993zM14.902 41.152a4.095 4.095 0 012.633-5.164c2.155-.7 4.47.477 5.171 2.63l-7.804 2.534zM19.192 44.442a4.105 4.105 0 01-5.012 2.923l1.062-4.04 3.95 1.117zM4.92 47.747l7.461.704a4.102 4.102 0 01-4.472 3.693 4.098 4.098 0 01-3.267-2.212l.278-2.185zM11.559 44.783l-5.76-3.934v-.002l.238-1.877a4.11 4.11 0 014.452.118 4.093 4.093 0 011.07 5.695zM23.09 60.651l-5.681-.72 5.686-1.934a4.061 4.061 0 01-.005 2.654zM19.101 50.513a4.11 4.11 0 015.764.681 4.094 4.094 0 01-.682 5.755L19.1 50.513zM17.492 55.668a4.102 4.102 0 01-4.479-3.684l4.165-.4.314 4.084zM39.619 42.911a4.103 4.103 0 01-7.623-1.255l7.275-1.32a9.2 9.2 0 01.348 2.575zM37.036 36.43a4.09 4.09 0 01-2.834 2.38l-1.14-4.845a9.262 9.262 0 013.974 2.466zM25.08 37.52l-4.041-6.44 5.643 1.374a4.093 4.093 0 01-1.602 5.065zM31.978 39.456a4.107 4.107 0 01-5.767-.644l3.269-2.606 2.498 3.25zM34.37 59.724a9.335 9.335 0 01-3.439 1.44 4.093 4.093 0 01-1.521-6.965l4.96 5.525zM38.822 49.747v.002l-.438 3.438a9.307 9.307 0 01-1.062 3.287c-.499.183-1.042.273-1.606.246l.376-8.186c1.074.05 2.03.505 2.73 1.213zM31.277 53.033a4.096 4.096 0 01-2.468-5.245l3.935 1.416-1.467 3.829z"
        fill="#42EC9A"
      />
      <path
        d="M26.12 40.204a4.093 4.093 0 01.201 5.791 4.108 4.108 0 01-5.8.202l5.599-5.993zM14.902 41.152a4.095 4.095 0 012.633-5.164c2.155-.7 4.47.477 5.171 2.63l-7.804 2.534zM19.192 44.442a4.105 4.105 0 01-5.012 2.923l1.062-4.04 3.95 1.117zM4.92 47.747l7.461.704a4.102 4.102 0 01-4.472 3.693 4.098 4.098 0 01-3.267-2.212l.278-2.185zM11.559 44.783l-5.76-3.934v-.002l.238-1.877a4.11 4.11 0 014.452.118 4.093 4.093 0 011.07 5.695zM23.09 60.651l-5.681-.72 5.686-1.934a4.061 4.061 0 01-.005 2.654zM19.101 50.513a4.11 4.11 0 015.764.681 4.094 4.094 0 01-.682 5.755L19.1 50.513zM17.492 55.668a4.102 4.102 0 01-4.479-3.684l4.165-.4.314 4.084zM39.619 42.911a4.103 4.103 0 01-7.623-1.255l7.275-1.32a9.2 9.2 0 01.348 2.575zM37.036 36.43a4.09 4.09 0 01-2.834 2.38l-1.14-4.845a9.262 9.262 0 013.974 2.466zM25.08 37.52l-4.041-6.44 5.643 1.374a4.093 4.093 0 01-1.602 5.065zM31.978 39.456a4.107 4.107 0 01-5.767-.644l3.269-2.606 2.498 3.25zM34.37 59.724a9.335 9.335 0 01-3.439 1.44 4.093 4.093 0 01-1.521-6.965l4.96 5.525zM38.822 49.747v.002l-.438 3.438a9.307 9.307 0 01-1.062 3.287c-.499.183-1.042.273-1.606.246l.376-8.186c1.074.05 2.03.505 2.73 1.213zM31.277 53.033a4.096 4.096 0 01-2.468-5.245l3.935 1.416-1.467 3.829z"
        fill="#42EC9A"
      />
      <path
        d="M73.044 70.333c-.734.765-1.91 1.268-2.94 1.52-4.298 1.053-8.593 1.739-12.89 2.792 1.24 1.209 1.276 2.857.68 4.482-.865 2.355-3.242 3.554-4.998 4.023-3.996 1.062-6.38 1.236-10.7 1.947L31.42 86.816 9.325 90.34a11.493 11.493 0 01-9.22-2.848 11.451 11.451 0 01-3.783-8.865l1.282-39.075c.2-6.324 5.495-11.29 11.83-11.091 6.332.199 11.307 5.485 11.107 11.81l-.859 25.554c3.457-.493 7.864-.804 11.487-1.268 3.41-.435 6.121-1.007 6.677-1.995.381-.93 1.173-2.503 1.847-3.247 1.28-1.41 3.131-2.342 5.035-2.313 1.904.03 3.538 1.81 4.367 3.523 4.387-2.023 11.254-5.14 15.907-6.44.822-.227 1.668-.46 2.517-.36 2.348.277 2.72 3.34 1.597 4.541-.526.56-1.143 1.02-1.795 1.43-3.108 1.953-11.013 7.12-11.013 7.12s9.242-1.217 14.053-1.303c.777-.014 1.596.075 2.272.45 1.325.733 1.678 3.051.411 4.37z"
        fill="#FFCBD9"
      />
      <path
        d="M66.041 42.258a.25.25 0 01-.24-.32c1.523-5.109-1.624-9.793-1.656-9.84a.249.249 0 01.411-.28c.034.047 3.316 4.921 1.723 10.263a.249.249 0 01-.238.177zM56.308 67.231a.249.249 0 01-.133-.46c.066-.043 6.739-4.272 9.736-6.31.356-.242.667-.452.942-.636 1.183-.798 1.672-1.127 2.082-1.564.534-.571.689-1.618.37-2.49-.177-.483-.65-1.31-1.836-1.451-.78-.092-2.465.206-3.332.447-4.174 1.166-9.398 3.586-13.596 5.53l-1.341.62a.25.25 0 01-.331-.123c-.007-.017-.754-1.637-1.723-2.662-.769-.815-2.358-1.552-4.028-1.243-1.18.217-2.819 1.038-3.927 3.722-.74 1.785-1.974 2.9-3.673 3.315a.249.249 0 11-.118-.484c1.557-.38 2.646-1.369 3.33-3.02 1.195-2.892 2.997-3.784 4.299-4.023 1.864-.345 3.594.451 4.481 1.39.814.861 1.458 2.072 1.706 2.571l1.117-.517c4.214-1.952 9.458-4.38 13.67-5.558.775-.214 2.602-.572 3.525-.462 1.06.124 1.878.773 2.246 1.775.387 1.055.193 2.291-.473 3.002-.45.477-.952.816-2.167 1.635-.275.185-.584.394-.94.637-2.332 1.585-6.879 4.491-8.828 5.733 1.485-.175 4.201-.49 6.298-.713a.25.25 0 01.275.22.25.25 0 01-.222.275c-3.022.322-7.332.836-7.376.84-.013.004-.023.004-.033.004zM12.53 19.5a.248.248 0 01-.158-.442c1.033-.848.903-2.128.901-2.14a.25.25 0 01.498-.056c.006.063.16 1.564-1.08 2.58a.25.25 0 01-.16.058z"
        fill="#222730"
      />
      <path
        d="M15.89 19.58c-1.28 0-2.163-.692-2.722-1.372a.248.248 0 01.035-.35.25.25 0 01.351.034c.937 1.142 2.147 1.465 3.594.96a.627.627 0 01.17-.047.252.252 0 01.267.23c.01.129-.08.242-.204.263l-.067.024c-.521.18-.994.259-1.423.259zm1.484-.28c-.007.002-.014.002-.02.002.005 0 .012 0 .02-.002z"
        fill="#222730"
      />
      <path
        d="M14.176 11.422h0c.136.327.126.677 0 .98h0a1.27 1.27 0 01-.694.693h0l-3.965 1.64h0a1.285 1.285 0 01-1.679-.693 1.278 1.278 0 01.695-1.675h0l3.964-1.64s0 0 0 0a1.286 1.286 0 011.679.695z"
        fill="#FCFCFC"
        stroke="#FCFCFC"
      />
      <path
        d="M11.45 10.621l.24 3.757-1.982.821a1.785 1.785 0 01-2.332-.964 1.778 1.778 0 01.966-2.328l3.109-1.286z"
        fill="#222730"
      />
      <path
        d="M23.32 8.753a1.77 1.77 0 01-.965.964l-1.366-3.29a1.785 1.785 0 012.332.964c.188.453.174.942 0 1.362zM21.18 6.89a1.285 1.285 0 011.679.693c.135.327.126.677 0 .98h0a1.269 1.269 0 01-.694.693h-.001l-3.965 1.642h0a1.285 1.285 0 01-1.678-1.675c.126-.304.367-.558.694-.693h0l3.965-1.64h0z"
        fill="#FCFCFC"
        stroke="#FCFCFC"
      />
      <path
        d="M20.132 6.78l.24 3.758-1.981.821a1.785 1.785 0 01-2.332-.964 1.777 1.777 0 01.965-2.328l3.108-1.286z"
        fill="#222730"
      />
      <path
        d="M17.066 15.88c-.261 0-.526-.026-.796-.078a.252.252 0 01-.198-.292.25.25 0 01.293-.198c.38.073.75.088 1.107.043.538-.066 1.035-.482 1.238-1.033.163-.444.099-.873-.181-1.178l-2.255-2.468a.248.248 0 01.016-.353.249.249 0 01.353.017l2.255 2.468c.403.442.51 1.072.282 1.687-.267.72-.927 1.266-1.646 1.356-.153.021-.31.03-.468.03z"
        fill="#222730"
      />
    </svg>
  )
}
