import { JadeMask } from '@stone-payments/jade'

const masks = {
  '8': '9999 9999',
  '12': '9 99999 99999 9',
  '13': '9 999999 999999',
  '14': '9 99 99999 99999 9'
}

export const formatGTIN = (gtin: string) => {
  const value = gtin?.replace(/\D/g, '')
  const length = value.length
  if (length === 0) return ''

  let pattern = masks[8]
  if (length === 14) {
    pattern = masks[14]
  } else if (length === 13) {
    pattern = masks[13]
  } else if (length > 8) {
    pattern = masks[12]
  }

  const mask = new JadeMask(pattern)

  return mask.compile(value)
}
