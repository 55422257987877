import { generate, sessionKeyStorage } from '@credit-web/common/browser-utils'
import { type AxiosRequestConfig } from 'axios'

export const bffHeadersInterceptor = (data: AxiosRequestConfig) => {
  const bffHearders = {
    'Saga-Key': generate.sagaKey(),
    'Session-Key': sessionKeyStorage.get()
  }
  return { ...data, headers: { ...data.headers, ...bffHearders } }
}
